import { FC, ReactNode } from 'react';

import { Icon } from 'components/Icon';
import { TIconName } from 'assets/js/variables/icon';

import styles from './DefList.module.scss';

interface DefListInterface {
  (props: DefListProps): JSX.Element;
  Term: FC<DefListTermProps>;
  Def: FC<DefListDefProps>;
  Item: FC<DefListItemProps>;
}

interface DefListProps {
  children: ReactNode;
}

interface DefListTermProps {
  children: ReactNode;
  iconName?: TIconName;
}

interface DefListDefProps {
  children: ReactNode;
}

interface DefListItemProps {
  children: ReactNode;
}

export const DefList: DefListInterface = (props) => <dl className={styles.wrapper} {...props} />;

DefList.Item = (props): JSX.Element => <div className={styles.item} {...props} />;

DefList.Term = ({ children, iconName, ...props }): JSX.Element => (
  <dt {...props}>
    {iconName && <Icon iconName={iconName} className={styles.termIcon} aria-hidden="true" />}
    {children}
  </dt>
);

DefList.Def = (props): JSX.Element => <dd className={styles.def} {...props} />;
