import { Accordion, Flex, Icon, Stack } from '@timelog/ui-library';
import { HTMLAttributes, ReactNode } from 'react';

interface AccordionItemProps extends HTMLAttributes<HTMLElement> {
  buttonAutomationId: string;
  heading: string;
  children?: ReactNode;
  counter?: number;
}

export const AccordionItem = ({
  buttonAutomationId,
  children,
  heading,
  counter = 0,
  ...props
}: AccordionItemProps) => {
  const hasItems = counter > 0;
  return (
    <Accordion.Item {...props}>
      <Accordion.Trigger level={3} data-automation-id={buttonAutomationId}>
        <Flex>
          <Icon
            iconName={hasItems ? 'warning' : 'circleCheck'}
            tone={hasItems ? 'warning' : 'success'}
          />
          {`${heading}${!hasItems ? '' : ` (${counter})`}`}
        </Flex>
      </Accordion.Trigger>
      <Accordion.Panel>
        <Stack verticalMargin="xSmall">{children}</Stack>
      </Accordion.Panel>
    </Accordion.Item>
  );
};
