import { datadogRum } from '@datadog/browser-rum';
import { isDev } from 'src/utils/env';

if (
  process.env.REACT_APP_DATADOG_RUM_APP_ID &&
  process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN &&
  !isDev
) {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_RUM_APP_ID,
    clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    service: 'reactwebapp',
    env: process.env.REACT_APP_DATADOG_ENV,
    version: process.env.REACT_APP_VERSION,
    // Reduce this to 1 to cut some cost as long as we are not using it actively
    sampleRate: 1,
    trackInteractions: true,
  });
}
