import { Avatar, Flex, Icon, Stack, Text } from '@timelog/ui-library';

interface PersonalInfoLinkContentProps {
  name: string;
  avatar?: string | null;
  email: string;
  companyName: string;
}

const PersonalInfoLinkContent = ({
  avatar,
  name,
  email,
  companyName,
}: PersonalInfoLinkContentProps) => (
  <Flex verticalAlignment="Top" data-automation-id="UserMenuOrganisationInfo">
    {avatar ? (
      <Avatar
        src={avatar}
        size="xxLarge"
        data-automation-id="UserMenuOrganisationInfoAvatarImage"
      />
    ) : (
      <Icon
        iconName="darkProfile"
        size="xxLarge"
        data-automation-id="UserMenuOrganisationInfoAvatarIcon"
        color="#666"
      />
    )}

    <Flex.Item fillRest>
      <Stack verticalMargin="xSmall">
        <div>
          <Text bold wrapWords data-automation-id="UserMenuOrganisationInfoName">
            {name}
          </Text>
          <Text dimmed wrapWords data-automation-id="UserMenuOrganisationInfoEmail">
            {email}
          </Text>
        </div>
        <Text
          dimmed
          lineEllipsis
          size="xSmall"
          data-automation-id="UserMenuOrganisationInfoCompanyName"
        >
          {companyName}
        </Text>
      </Stack>
    </Flex.Item>
  </Flex>
);
export default PersonalInfoLinkContent;
