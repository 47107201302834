import { Route, RouteProps, useLocation } from 'react-router-dom';
import { useGetDesignSevenPages } from 'src/apis/pagesAPI/designSevenAPI';
import LoadingSpinner from 'src/components/LoadingSpinner';
import { RedirectObject } from 'src/routers/RedirectDesignSeven/RedirectObject';

interface AuthRouteProps extends Omit<RouteProps, 'render' | 'component'> {
  children: JSX.Element;
}

const LOADING = 'loading';
const CONTINUE = 'continue';

interface IPageItem {
  pageUrl: string;
  pageIdentifier: string;
}

interface IIsAPILoadingLegacyPage {
  pathname: string;
  isLoading: boolean;
  designSevenPageList: Array<IPageItem>;
}

const isAPILoadingLegacyPage = ({
  pathname,
  isLoading,
  designSevenPageList,
}: IIsAPILoadingLegacyPage) => {
  if (isLoading) return LOADING;

  if (designSevenPageList.length > 0) {
    const result = Object.entries(RedirectObject).map(([key, { oldPaths }]) => {
      const isContainPageIdenitifer = designSevenPageList?.find(
        ({ pageIdentifier }) => pageIdentifier === key,
      );
      if (isContainPageIdenitifer && oldPaths.some((path) => path === pathname)) return LOADING;
      return null;
    });
    if (result.some((item) => item === LOADING)) {
      return LOADING;
    }
  }

  return CONTINUE;
};

export const isAPILoadingLegacyPageForTest = isAPILoadingLegacyPage;

export const LegacyRoute = ({ children, ...rest }: AuthRouteProps) => {
  const { pathname } = useLocation();
  const { isLoading: isDesignSevenLoading, designSevenPageList } = useGetDesignSevenPages();
  const isLoading =
    isAPILoadingLegacyPage({ isLoading: isDesignSevenLoading, pathname, designSevenPageList }) ===
    LOADING;

  return <Route {...rest} render={() => (isLoading ? <LoadingSpinner /> : children)} />;
};

export default LegacyRoute;
