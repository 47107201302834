import { Route, RouteProps, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { APP_URL } from 'src/consts/cookies';
import { TARGET_DESTINATION } from 'src/consts/localstorage';
import { loginPortalUrl } from 'src/utils/url';

interface AuthRouteProps extends Omit<RouteProps, 'render' | 'component'> {
  children: JSX.Element;
}

const getRedirectPath = (pathname: string): 'children' | 'login' => {
  const appUrl = Cookies.get(APP_URL);

  if (appUrl) {
    return 'children';
  }

  localStorage.setItem(TARGET_DESTINATION, pathname);

  return 'login';
};

export const getRedirectPathForTest = getRedirectPath;

export const AuthRoute = ({ children, ...rest }: AuthRouteProps) => {
  const { pathname } = useLocation();

  const result = getRedirectPath(pathname);

  return (
    <Route
      {...rest}
      render={() =>
        result === 'login' ? (
          <Route
            render={() => {
              window.location.href = loginPortalUrl;
              return null;
            }}
          />
        ) : (
          children
        )
      }
    />
  );
};
