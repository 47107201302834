import { HTMLAttributes, FC, useState } from 'react';
import cx from 'classnames';
import { Icon } from '@timelog/ui-library';
import styles from '../../Navigation.module.scss';

interface MobileNavTreeToggleProps extends HTMLAttributes<HTMLButtonElement> {
  id: string;
  name: string;
  inititalOpen?: boolean;
}

export const MobileNavTreeToggle: FC<MobileNavTreeToggleProps> = ({
  id,
  name,
  inititalOpen = false,
  ...props
}) => {
  const [isActive, setIsActive] = useState(inititalOpen);

  return (
    <button
      type="button"
      aria-controls={id}
      aria-expanded={isActive}
      className={cx(styles.TriggerSubNavButton, {
        [styles.TriggerSubNavButtonIsActive]: isActive,
      })}
      onClick={() => setIsActive((prev) => !prev)}
      {...props}
    >
      {name}
      <Icon iconName="pointDown" size="medium" />
    </button>
  );
};
