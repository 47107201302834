import { createContext, useContext, useReducer } from 'react';
import sideMenuReducer, {
  SideMenuStoreProps,
  initSideMenuStore,
  SideMenuActionProps,
} from 'src/reducers/SideMenuReducer';

interface ISideMenuStoreProps {
  children: JSX.Element | JSX.Element[];
}
const SideMenuStoreContext = createContext<SideMenuStoreProps>(initSideMenuStore);
const SideMenuDispatchContext = createContext<React.Dispatch<SideMenuActionProps> | undefined>(
  undefined,
);
export const useSideMenuDispatch = () => useContext(SideMenuDispatchContext);
export const useSideMenuStore = () => useContext(SideMenuStoreContext);

export default ({ children }: ISideMenuStoreProps) => {
  const [sideMenuState, sideMenuDispatch] = useReducer(sideMenuReducer, initSideMenuStore);

  return (
    <SideMenuStoreContext.Provider value={sideMenuState}>
      <SideMenuDispatchContext.Provider value={sideMenuDispatch}>
        {children}
      </SideMenuDispatchContext.Provider>
    </SideMenuStoreContext.Provider>
  );
};
