import { FC, useRef, useState } from 'react';
import cx from 'classnames';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { Tooltip } from '@timelog/ui-library';
import { useIsThereRoomForTheText } from './truncatedNavLinkHookHelper';
import styles from './TruncatedNavLink.module.scss';

export interface TruncatedNavLinkProps extends NavLinkProps {
  className: string;
}

export const TruncatedNavLink: FC<TruncatedNavLinkProps> = ({ className, ...props }) => {
  const textRef = useRef<HTMLAnchorElement>(null);
  const [ellipsisIsActive, setEllipsisIsActive] = useState(false);
  const stylesObject = cx([styles.Text, className]);

  useIsThereRoomForTheText(textRef, ellipsisIsActive, setEllipsisIsActive);

  return ellipsisIsActive ? (
    <Tooltip label={props.children}>
      <NavLink className={stylesObject} ref={textRef} {...props} />
    </Tooltip>
  ) : (
    <NavLink className={stylesObject} ref={textRef} {...props} />
  );
};
