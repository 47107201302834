import { Table, TCellText, THorizontalAlignment } from '@timelog/ui-library';
import { memo, ReactNode } from 'react';

interface IMemoizedRenderBodyCellColumn {
  children: ReactNode;
  alignment?: THorizontalAlignment;
  editable?: boolean;
  type?: TCellText;
  fontColor?: string;
  dividerBorderLeft?: boolean;
  cellValue: string | undefined;
  startsAt?: string;
  endsAt?: string;
}

const MemoizedRenderBodyCellColumn = memo(
  ({
    children,
    alignment,
    editable,
    type,
    fontColor,
    dividerBorderLeft,
    // eslint-disable-next-line
    cellValue,
    // eslint-disable-next-line
    startsAt,
    // eslint-disable-next-line
    endsAt,
  }: IMemoizedRenderBodyCellColumn) => (
    <Table.Cell
      alignment={alignment}
      type={type}
      hasOverflow={editable}
      cellFontColor={fontColor}
      dividerBorderLeft={dividerBorderLeft}
    >
      {children}
    </Table.Cell>
  ),
  (prevProp, nextProp) => {
    const isSameCellValue = prevProp.cellValue === nextProp.cellValue;
    const isSameStartsAt = prevProp.startsAt === nextProp.startsAt;
    const isSameEndsAt = prevProp.endsAt === nextProp.endsAt;

    return isSameCellValue && isSameStartsAt && isSameEndsAt;
  },
);

export default MemoizedRenderBodyCellColumn;
