const convertDateToLocaleString = (date: Date, locale: string) => {
  const dateString = date.toLocaleDateString(locale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  // In TimeLog, da-DK format follows dd-mm-yyyy instead of the standard dd.mm.yyyy.
  if (locale !== 'da-DK') {
    return dateString;
  }

  return dateString.replace(/\./g, '-');
};

export { convertDateToLocaleString };
