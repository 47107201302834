import { FC, ReactNode } from 'react';
import { Icon } from '@timelog/ui-library';
import styles from './LinkInnerContent.module.scss';

interface LinkInnerContentProps {
  label: string;
  hasSubnav?: boolean;
  icon?: ReactNode;
}

export const LinkInnerContent: FC<LinkInnerContentProps> = ({ label, hasSubnav, icon }) => (
  // No block elements inside as it is a button
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button
  <>
    {icon && <span className={styles.IconId}>{icon}</span>}
    <span>{label}</span>
    {hasSubnav && (
      <span className={styles.IconArrow}>
        <Icon iconName="pointDown" size="small" name="arrowIcon" />
      </span>
    )}
  </>
);
