import { Switch, Route } from 'react-router-dom';
import ScreenHeader from 'src/components/ScreenHeader';
import ResourcePlanner from 'src/screens/ResourcePlanner';
import { designSevenPagePath } from 'src/designSevenPagePath';
import PageRoute from '../PageRoute';

export const ProjectsRouter = () => (
  <Switch>
    <Route path={designSevenPagePath.resourcePlanner}>
      <ScreenHeader />
      <PageRoute
        pageTitle="ResourcePlanner"
        pageId="c210b1c5-f3df-44d8-b17c-43c10a504c0d"
        pageIdentifier="NewResourcePlanner"
      >
        <ResourcePlanner />
      </PageRoute>
    </Route>
  </Switch>
);
