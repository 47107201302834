import { ElementType, HTMLAttributes, ReactNode } from 'react';
import classNames from 'classnames';
import { TSizes } from 'assets/js/variables/size';
import styles from './Stack.module.scss';

interface StackProps extends HTMLAttributes<HTMLElement> {
  /** Content of Stack container */
  children: ReactNode;
  /** Vertical margin */
  verticalMargin?: TSizes;
  /** Render as tag */
  as?: keyof Pick<HTMLElementTagNameMap, 'div' | 'dl'>;
}

export const Stack = ({
  verticalMargin = 'medium',
  as: As = 'div',
  className,
  ...props
}: StackProps) => {
  const Tag = As as ElementType;
  return (
    <Tag
      className={classNames(styles.wrapper, className, {
        [styles[verticalMargin]]: verticalMargin,
      })}
      {...props}
    />
  );
};
