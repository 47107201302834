import { Helmet } from 'react-helmet-async';
import { isDev } from 'src/utils/env';

const DigitalAdoptionPlugin = () => {
  if (isDev) {
    return null;
  }

  return (
    <Helmet>
      <script
        async
        type="text/javascript"
        src="https://eucdn.whatfix.com/prod/f069b090-b985-11ec-93c7-bac83aa657f7/initiatorembed/initiatorembed.nocache.js"
      />
    </Helmet>
  );
};

export default DigitalAdoptionPlugin;
