import { Flex } from '@timelog/ui-library';
import classNames from 'classnames';
import HeaderTitle from './components/HeaderTitle';
import MenuButton from './components/MenuButton';
import BookmarkButton from './components/BookmarkButton';
import styles from './ScreenHeader.module.scss';

interface ScreenHeaderProps {
  urlForTest?: string;
}

export default ({ urlForTest }: ScreenHeaderProps) => (
  <div data-automation-id="ScreenHeader" className={classNames(styles.ScreenHeaderContainer)}>
    <Flex>
      <HeaderTitle />
      <Flex verticalAlignment="center" noGap>
        <BookmarkButton />
        <MenuButton urlForTest={urlForTest} />
      </Flex>
    </Flex>
  </div>
);
