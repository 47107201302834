import { forwardRef, InputHTMLAttributes } from 'react';
import cx from 'classnames';
import useAutoId from 'hooks/useAutoId';
import { TIconButtonSize } from 'assets/js/variables/button';
import { Icon } from 'components/Icon';
import { IconButtonBaseSharedProps } from 'components/IconButton/IconButtonBase';
import { VisuallyHidden } from 'components/VisuallyHidden';
import { IconButtonSizeMapping } from 'components/IconButton/IconButton';
import buttonStyles from '../IconButton/IconButton.module.scss';
import styles from './FileInput.module.scss';

type FileInputRef = HTMLInputElement;

interface FileInputProps
  extends Omit<IconButtonBaseSharedProps, 'as' | 'children'>,
    Omit<InputHTMLAttributes<HTMLInputElement>, 'children' | 'size'> {
  iconSize?: TIconButtonSize;
}

export const FileInput = forwardRef<FileInputRef, FileInputProps>(
  ({ iconSize = 'default', border, tooltipText, size = 'default', ...props }, ref) => {
    const autoId = useAutoId();
    return (
      <div
        className={cx(styles.wrapper, buttonStyles.iconButton, {
          [buttonStyles[`${size}`]]: size,
          [buttonStyles.border]: border,
        })}
      >
        <input id={autoId} type="file" ref={ref} {...props} className={styles.input} />
        <label htmlFor={autoId} title={tooltipText} className={styles.label}>
          <VisuallyHidden>{tooltipText}</VisuallyHidden>
          <Icon size={IconButtonSizeMapping[iconSize]} iconName="formatInsertPhoto" />
        </label>
      </div>
    );
  },
);
