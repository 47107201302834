import { createContext, useContext, useReducer } from 'react';
import widgetListReducer, {
  IWidgetListProps,
  initWidgetListStore,
  TWidgetDashboardActionProps,
} from '../reducer/WidgetDashboardReducer';
import { IWidget } from '../WidgetType';

interface IWidgetDashboardStoreProps {
  widgetList: IWidget[];
  children: JSX.Element | JSX.Element[];
}

const WidgetDashboardStoreContext = createContext<IWidgetListProps>(initWidgetListStore);
const WidgetDashboardDispatchContext = createContext<
  React.Dispatch<TWidgetDashboardActionProps> | undefined
>(undefined);
export const WidgetDashboardProviderForTest = WidgetDashboardStoreContext.Provider;

export const useWidgetDashboardDispatch = ():
  | React.Dispatch<TWidgetDashboardActionProps>
  | undefined => useContext(WidgetDashboardDispatchContext);

export const useWidgetList = () => useContext(WidgetDashboardStoreContext)?.widgetList || [];

export default ({ children, widgetList }: IWidgetDashboardStoreProps) => {
  const [widgetListState, widgetListDispatch] = useReducer(widgetListReducer, { widgetList });

  return (
    <WidgetDashboardStoreContext.Provider value={widgetListState}>
      <WidgetDashboardDispatchContext.Provider value={widgetListDispatch}>
        {children}
      </WidgetDashboardDispatchContext.Provider>
    </WidgetDashboardStoreContext.Provider>
  );
};
