import cx from 'classnames';
import { TIconName } from 'assets/js/variables/icon';
import { TStatus } from 'assets/js/variables/status';
import { Flex } from 'components/Flex';
import { Icon } from 'components/Icon';
import { Stack } from 'components/Stack';
import { HTMLAttributes, ReactNode } from 'react';

import styles from './Alert.module.scss';

const iconName: Record<TStatus, TIconName> = {
  error: 'error',
  success: 'circleCheck',
  warning: 'warning',
  default: 'info',
};

interface AlertProps extends HTMLAttributes<HTMLElement> {
  tone?: TStatus;
  children: ReactNode;
}

export const Alert = ({ children, tone = 'default', ...props }: AlertProps) => (
  <div
    className={cx(styles.wrapper, {
      [styles.default]: tone === 'default',
      [styles.error]: tone === 'error',
      [styles.success]: tone === 'success',
      [styles.warning]: tone === 'warning',
    })}
    {...props}
  >
    <Flex>
      <Icon iconName={iconName[tone]} size="large" />
      <Stack>{children}</Stack>
    </Flex>
  </div>
);
