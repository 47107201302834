import {
  GROUP_BY_PROJECT_KEY,
  GROUP_BY_RESOURCE_KEY,
  GROUP_BY_RESOURCE_TOTAL_KEY,
  useGetMigrationStatus,
  useGetResourceViewOptions,
} from 'src/apis/resourcePlannerAPI';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetFilterAPI } from 'src/apis/filterAPI';
import FilterLayout from 'src/components/FilterLayout';
import ResponseHandler from 'src/components/ResponseHandler';
import { useGetCurrentPageIdentifier } from 'src/stores/PageStore';
import { addDays, format, parse, differenceInDays, addMonths } from 'date-fns';
import { IFilterProps, TFilterID } from 'src/reducers/FilterReducer/FilterReducer';
import { useQueryClient } from '@tanstack/react-query';
import { useFilterStore } from 'src/stores/FilterStore';
import ResouceViewOptions from './components/ResouceViewOptions';
import { IResouceViewField } from './components/ResouceViewOptions/ResouceViewOptions';
import toPascal from './helper/toPascal';
import ResourceTableGroupedByEmployee from './components/ResourceTableGroupedByEmployee';
import ResourceTableGroupedByProject from './components/ResourceTableGroupedByProject';
import { ViewType } from './types/resourcePlanner';
import {
  RPinvalidateOldQueryOnViewOptionChange,
  RPSlectedFilterListStateKey,
  RPViewOptionsStateKey,
} from './localStorageKeys';
import { InitialSetup } from './components/InitialSetup';
import { useSignalRConnection } from './hooks';

type SelectedViewOptions = {
  [key: string]: string;
};

export default () => {
  const { t } = useTranslation('resourcePlanner');
  const qc = useQueryClient();
  const safeParseJson = (jsonString: string) => {
    try {
      return JSON.parse(jsonString);
    } catch (ex) {
      return null;
    }
  };
  const localViewOptions = localStorage.getItem(RPViewOptionsStateKey);
  const [changedViewOptions, setChangedViewOptions] = useState<SelectedViewOptions>(
    localViewOptions ? safeParseJson(localViewOptions) || {} : {},
  );
  useEffect(() => {
    localStorage.setItem(RPViewOptionsStateKey, JSON.stringify(changedViewOptions));
  }, [changedViewOptions]);

  useSignalRConnection();

  const { fields: initialFields } = useGetResourceViewOptions();

  const { filterQueryObj } = useFilterStore();

  const {
    isResourceMigrated,
    isProjectMigrated,
    isResourcePlanMigrated,
    isError: isMigratedError,
    isLoading: isMigratedLoading,
  } = useGetMigrationStatus();

  // #region Date range
  const getEndDateForViewOptions = (startDate: string, endDate: string) => {
    if (!changedViewOptions['period-ends-at']) {
      return addDays(addMonths(new Date(), 3), -1);
    }
    if (parse(startDate, 'yyyy-MM-dd', new Date()) > new Date()) {
      return parse(endDate, 'yyyy-MM-dd', new Date());
    }

    const difference = differenceInDays(
      parse(endDate, 'yyyy-MM-dd', new Date()),
      parse(startDate, 'yyyy-MM-dd', new Date()),
    );
    const date = addDays(new Date(), difference);
    return date;
  };

  const getStartDateForViewOptions = (startDate: string) => {
    if (parse(startDate, 'yyyy-MM-dd', new Date()) > new Date()) {
      return parse(startDate, 'yyyy-MM-dd', new Date());
    }
    return new Date();
  };

  const [initialDateEnd, setInitialDateEnd] = useState(
    getEndDateForViewOptions(
      changedViewOptions['period-starts-at'],
      changedViewOptions['period-ends-at'],
    ),
  );

  const [initialDateStart, setInitialDateStart] = useState(
    getStartDateForViewOptions(changedViewOptions['period-starts-at']),
  );
  // #endregion Date range

  const fields: Array<IResouceViewField> = useMemo(
    () =>
      initialFields.map((field) => {
        const value = (() => {
          if (changedViewOptions && changedViewOptions[field.name]) {
            return changedViewOptions[field.name];
          }
          return field.value;
        })();

        return {
          ...field,
          value,
          options: field.options.map((option) => ({
            ...option,
            label: t(`FieldOption${toPascal(option.value)}` as any),
          })),
        };
      }),
    [initialFields, t, changedViewOptions],
  );
  const groupedBy: ViewType = useMemo(() => {
    const groupedByViewOption = fields.find(({ name }) => name === 'grouped-by');
    return groupedByViewOption?.value as ViewType;
  }, [fields]);
  const selectedViewOptions = useMemo(() => {
    const fieldsFromEndpoint = fields.reduce<SelectedViewOptions>(
      (acc, { name, value }) => {
        if (value) {
          acc[name] = value;
        }
        return acc;
      },
      {
        'period-starts-at': format(initialDateStart, 'yyyy-MM-dd'),
        'period-ends-at': format(initialDateEnd, 'yyyy-MM-dd'),
      },
    );
    return {
      ...fieldsFromEndpoint,
    };
  }, [fields, initialDateStart, initialDateEnd]);

  const isGroupedByResource = groupedBy === 'group-by-resource';
  const isGroupedByProject = groupedBy === 'group-by-work-item';

  interface OptionsChangeParameters {
    [key: string]: string;
  }

  const onViewOptionsChange = (items: OptionsChangeParameters[]) => {
    const options = items.map((item) => {
      if (item.name === 'period-starts-at') {
        setInitialDateStart(parse(item.value, 'yyyy-MM-dd', new Date()));
      }

      if (item.name === 'period-ends-at') {
        setInitialDateEnd(parse(item.value, 'yyyy-MM-dd', new Date()));
      }
      return { [item.name]: item.value };
    });
    const optionsToObject = Object.assign({}, ...options);
    const invalidateOldQueries = localStorage.getItem(RPinvalidateOldQueryOnViewOptionChange);
    if (invalidateOldQueries === 'true') {
      // remove old queries of they are no longer in sync with expand / collapse state
      qc.removeQueries([GROUP_BY_RESOURCE_KEY, changedViewOptions || {}, filterQueryObj || {}]);
      qc.removeQueries([GROUP_BY_PROJECT_KEY, changedViewOptions || {}, filterQueryObj || {}]);
      qc.removeQueries([
        GROUP_BY_RESOURCE_TOTAL_KEY,
        changedViewOptions || {},
        filterQueryObj || {},
      ]);
      localStorage.setItem(RPinvalidateOldQueryOnViewOptionChange, 'false');
    }

    setChangedViewOptions({
      ...changedViewOptions,
      ...optionsToObject,
    });
  };

  const pageIdentifier = useGetCurrentPageIdentifier();
  const { filterList, isError, isLoading } = useGetFilterAPI(pageIdentifier);

  const getSelectedFilterFromLocalStorage = (): Record<TFilterID, IFilterProps> => {
    const filterFromLocalStorage: Record<TFilterID, IFilterProps> =
      safeParseJson(localStorage.getItem(RPSlectedFilterListStateKey) || '') || {};
    if (filterList.length > 0) {
      const flatMapped = filterList.flatMap((x) => x.filterItems).map((x) => x?.name);
      Object.keys(filterFromLocalStorage).forEach((key) => {
        if (flatMapped.indexOf(key) === -1) {
          delete filterFromLocalStorage[key];
        }
      });
    }
    return filterFromLocalStorage;
  };

  return (
    <ResponseHandler
      isLoading={isLoading || isMigratedLoading}
      isError={isError || isMigratedError}
    >
      {isResourceMigrated && isProjectMigrated && isResourcePlanMigrated ? (
        <ResponseHandler isLoading={isLoading} isEmpty={filterList.length <= 0} isError={isError}>
          <FilterLayout
            filterList={filterList}
            selectedFilterList={getSelectedFilterFromLocalStorage()}
            viewOptions={
              <ResouceViewOptions
                onChange={onViewOptionsChange}
                fields={fields || []}
                initialDateStart={initialDateStart}
                initialDateEnd={initialDateEnd}
              />
            }
          >
            <>
              {isGroupedByResource && (
                <ResourceTableGroupedByEmployee selectedViewOptions={selectedViewOptions} />
              )}
              {isGroupedByProject && (
                <ResourceTableGroupedByProject selectedViewOptions={selectedViewOptions} />
              )}
            </>
          </FilterLayout>
        </ResponseHandler>
      ) : (
        <InitialSetup />
      )}
    </ResponseHandler>
  );
};
