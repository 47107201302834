import { IconButton, ContextMenuWithListItems } from '@timelog/ui-library';
import { useGetPageActions } from 'src/apis/pagesAPI/designSevenAPI';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

interface IMenuButton {
  urlForTest?: string;
}

export default ({ urlForTest }: IMenuButton) => {
  const { t } = useTranslation('header');
  const { pathname } = useLocation();
  const { pageAction } = useGetPageActions(urlForTest || pathname);

  if (pageAction.length > 0)
    return (
      <ContextMenuWithListItems
        as={IconButton}
        text="menu"
        iconName="moreVertical"
        iconSize="large"
        tooltipText={t('ScreenHeaderActionMenu')}
        data-automation-id="ScreenHeaderMoreActionButton"
        listItems={pageAction}
      />
    );

  return null;
};
