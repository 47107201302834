import { FC, HTMLAttributes, ReactNode } from 'react';
import {
  AvatarButton,
  ContextMenu,
  IconButton,
  themeColors,
  Tooltip,
  Chip,
} from '@timelog/ui-library';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGetHomePageUrl } from 'src/apis/menusAPI/mainAPI';
import { usePageSettingsMenu } from 'src/apis/menusAPI/pageSettingsAPI';
import { useSystemAdministrationMenu } from 'src/apis/menusAPI/systemAdministrationAPI';
import { usePersonalSettingsMenu } from 'src/apis/menusAPI/personalSettingsAPI';
import { useGetPageSetting } from 'src/stores/PageStore';
import { useIsValidIndexControlName, useGetCompanyName, useGetSiteStatus } from 'src/apis/indexAPI';
import { useProfilePicture, useGetUserFullName, useGetUserEmail } from 'src/apis/usersAPI/meAPI';
import PreviewFeaturesButton from './components/PreviewFeaturesButton';
import CustomerPortalButton from './components/CustomerPortalButton';
import PersonalInfoLinkContent from './components/PersonalInfoLinkContent';

import styles from './HeaderBase.module.scss';

interface HeaderProps extends HTMLAttributes<HTMLElement> {
  primaryArea: ReactNode;
  optionsArea: ReactNode;
}

export const HeaderBase: FC<HeaderProps> = ({ primaryArea, optionsArea, ...props }) => {
  const { t } = useTranslation('header');
  const {
    myTimeLogAccountUrl,
    error: systemAdminError,
    isSystemAdministrationMenuEmpty,
    defaultSystemUrl,
  } = useSystemAdministrationMenu();

  const { error: pageSettingError, isPageSettingMenuEmpty } = usePageSettingsMenu();
  const {
    isPersonalSettingMenuEmpty,
    defaultPersonalSettingUrl,
    personalInformationUrl,
    error: personalSettingError,
  } = usePersonalSettingsMenu();

  const userPreviewFeatures = 'UserPreviewFeatures';
  const { isValidIndexControlName: hasPreviewFeatureAccess } =
    useIsValidIndexControlName(userPreviewFeatures);
  const pageSettingPath = useGetPageSetting();
  const { profilePicture } = useProfilePicture();
  const { email } = useGetUserEmail();
  const { companyName } = useGetCompanyName();
  const { fullName } = useGetUserFullName();
  const { homeUrl } = useGetHomePageUrl();
  const { siteStatus } = useGetSiteStatus();

  return (
    <header className={`${styles.Wrapper} ${themeColors.Pink}`} {...props}>
      <div className={styles.Inner}>
        <Tooltip label={t('HomeLink', 'TimeLog Project')}>
          <Link
            className={styles.HomeLink}
            to={homeUrl}
            data-automation-id="HeaderHomeIcon"
            aria-label={t('HomeLink', 'TimeLog Project')}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 33 32"
              className={styles.HomeLinkLogo}
            >
              <path d="M19.162 19.39a3.372 3.372 0 01-2.067 1.502.422.422 0 00-.314.409h-.001v6.314c0 .467.362.965.806 1.109l8.066 2.621c.224.072.425.043.571-.063s.236-.289.236-.523v-8.48c0-.469-.362-.965-.807-1.11l-6.004-1.951a.426.426 0 00-.487.173zm-6.246-.172l-6.004 1.951c-.446.145-.807.641-.807 1.11v8.479c0 .235.091.418.237.524s.347.136.571.063l8.066-2.621c.445-.144.806-.642.806-1.109v-6.314h-.001a.423.423 0 00-.315-.409 3.364 3.364 0 01-2.065-1.502.421.421 0 00-.357-.193.369.369 0 00-.131.019zm.822-1.618a2.543 2.543 0 105.086 0 2.543 2.543 0 00-5.086 0zM8.687 9.366L.622 11.987c-.222.072-.369.215-.424.386s-.022.373.117.561l4.984 6.862c.275.379.859.567 1.304.424l6.004-1.951a.427.427 0 00.292-.427 3.368 3.368 0 01.789-2.429.424.424 0 00.014-.516h.001l-3.71-5.108c-.213-.294-.61-.473-.984-.473a1.072 1.072 0 00-.321.05zm13.884.424l-3.71 5.108a.424.424 0 00.014.516v.001c.274.325.492.706.634 1.136.139.43.186.868.155 1.292a.427.427 0 00.293.427h-.001l6.004 1.951c.446.144 1.03-.046 1.306-.424l4.985-6.862c.136-.189.171-.389.115-.561s-.201-.315-.424-.386l-8.066-2.621a1.042 1.042 0 00-.322-.049c-.375-.001-.772.18-.984.472zM15.784.971L10.8 7.831c-.276.379-.276.994 0 1.372l3.711 5.108c.11.152.312.217.494.146a3.377 3.377 0 012.555 0 .423.423 0 00.495-.146l3.71-5.108c.275-.379.275-.993 0-1.372L16.781.971c-.138-.19-.318-.285-.498-.285s-.361.094-.499.285z" />
            </svg>
          </Link>
        </Tooltip>
        {primaryArea}

        {siteStatus && siteStatus === 'Sandbox' ? (
          <div className={styles.SiteStatusIndicator}>
            <Chip label={siteStatus} color="warning" />
          </div>
        ) : null}

        {siteStatus && siteStatus === 'Learning' ? (
          <div className={styles.SiteStatusIndicator}>
            <Chip label={siteStatus} color="success" />
          </div>
        ) : null}

        <div className={styles.Options}>
          {optionsArea}

          <ContextMenu>
            {profilePicture ? (
              <ContextMenu.Trigger
                as={AvatarButton}
                avatar={profilePicture}
                iconSize="large"
                size="large"
                data-automation-id="HeaderOptionAvatarButton"
                tooltipText={t('UserMenuHeading')}
              >
                {t('UserMenuHeading')}
              </ContextMenu.Trigger>
            ) : (
              <ContextMenu.Trigger
                as={IconButton}
                iconName="darkProfile"
                iconSize="large"
                size="large"
                data-automation-id="HeaderOptionButton"
                tooltipText={t('UserMenuHeading')}
              >
                {t('UserMenuHeading')}
              </ContextMenu.Trigger>
            )}

            <ContextMenu.List>
              {fullName && email && companyName && personalInformationUrl ? (
                <>
                  <ContextMenu.Link
                    as={Link}
                    to={personalInformationUrl}
                    aria-label={t('UserMenuPersonalInformationsLink')}
                    wrapText
                    data-automation-id="HeaderPersonalInformation"
                  >
                    <PersonalInfoLinkContent
                      name={fullName}
                      email={email}
                      companyName={companyName}
                      avatar={profilePicture}
                    />
                  </ContextMenu.Link>

                  <ContextMenu.Divider />
                </>
              ) : null}

              {!personalSettingError && !isPersonalSettingMenuEmpty ? (
                <ContextMenu.Link
                  as={Link}
                  to={defaultPersonalSettingUrl}
                  data-automation-id="HeaderPersonalSetting"
                >
                  {t('UserMenuPersonalSettingsLink')}
                </ContextMenu.Link>
              ) : null}

              {pageSettingPath && !pageSettingError && !isPageSettingMenuEmpty ? (
                <ContextMenu.Link
                  as={Link}
                  to={pageSettingPath}
                  data-automation-id="HeaderPageSettings"
                >
                  {t('UserMenuPageSettingsLink')}
                </ContextMenu.Link>
              ) : null}

              <ContextMenu.Divider />

              {!systemAdminError && !isSystemAdministrationMenuEmpty ? (
                <ContextMenu.Link
                  as={Link}
                  to={defaultSystemUrl}
                  data-automation-id="HeaderSystemAdmin"
                >
                  {t('UserMenuSystemAdministrationLink')}
                </ContextMenu.Link>
              ) : null}

              <CustomerPortalButton />

              {!systemAdminError && myTimeLogAccountUrl ? (
                <ContextMenu.Link
                  as={Link}
                  to={myTimeLogAccountUrl}
                  data-automation-id="HeaderMyTimeLogAccount"
                >
                  {t('UserMenuMyTimeLogAccountLink')}
                </ContextMenu.Link>
              ) : null}

              <PreviewFeaturesButton />

              {!isSystemAdministrationMenuEmpty || hasPreviewFeatureAccess ? (
                <ContextMenu.Divider />
              ) : null}

              <ContextMenu.Link as={Link} to="/logoff" data-automation-id="HeaderSignOut">
                {t('UserMenuSignOutLink')}
              </ContextMenu.Link>
            </ContextMenu.List>
          </ContextMenu>
        </div>
      </div>
    </header>
  );
};
