import {
  Accordion,
  Alert,
  ButtonSecondary,
  Flex,
  Link,
  Shimmer,
  Stack,
  Text,
} from '@timelog/ui-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetNotificationCenter, useGetIsApprovalEnabled } from 'src/apis/notificationCenterAPI';
import ResponseHandler from 'src/components/ResponseHandler';
import WidgetHeader from 'src/components/WidgetHeader';
import { formatUrl } from 'src/utils/url';
import { AccordionItem, InfoModal, ListItem } from './components';

export const NotificationCenter = () => {
  const {
    timeSheetsToApprove,
    absenceToApprove,
    itemsToTakeActionOn,
    notificationCenterTimesheetItems,
    numberOfExpensesPendingApproval = 0,
    numberOfMileagePendingApproval = 0,
    approveExpensesUrl,
    isApprovalOfExpensesEnabled,
    isApprovalOfTimeSheetEnabled,
    closeTimeSheetReportUrl,
    approvalUrl,
    timeSheetsToBeClosedLength,
    isError,
    isLoading,
  } = useGetNotificationCenter();
  const { isApprovalEnabled } = useGetIsApprovalEnabled();
  const { t } = useTranslation('notificationCenter');
  const accordionItemsTimeSheets = isApprovalOfTimeSheetEnabled ? 3 : 0;
  const accordionItemsExpenses = isApprovalOfExpensesEnabled ? 2 : 0;
  const accordionItems = accordionItemsTimeSheets + accordionItemsExpenses;
  const defaultIndices = Array.from({ length: accordionItems }, (value, index) => index);
  const [indices, setIndices] = useState(defaultIndices);
  const showNotificationsCenter =
    isApprovalEnabled && (isApprovalOfTimeSheetEnabled || isApprovalOfExpensesEnabled);

  const expandCollapseHandler = () => {
    if (indices.length === defaultIndices.length) {
      return setIndices([]);
    }

    return setIndices(defaultIndices);
  };

  const toggleItem = (toggledIndex: number) => {
    if (indices.includes(toggledIndex)) {
      setIndices(indices.filter((currentIndex) => currentIndex !== toggledIndex));
    } else {
      setIndices([...indices, toggledIndex].sort());
    }
  };

  return (
    <ResponseHandler
      isError={isError}
      isLoading={isLoading}
      LoadingComponent={<Shimmer />}
      ErrorComponent={
        <Alert tone="error" data-automation-id="NotificationCenterAlertError">
          <Text>{t('CouldNotLoadNotificationCenterErrorMessage')}</Text>
        </Alert>
      }
    >
      {showNotificationsCenter ? (
        <Stack verticalMargin="small" data-automation-id="NotificationCenter">
          <WidgetHeader
            widgetKey="NotificationCenter"
            heading={t('Heading')}
            headingAs={2}
            iconName="gppMaybe"
            count={itemsToTakeActionOn}
            data-automation-id="NotificationCenterHeader"
            infoModal={<InfoModal />}
          />

          <div>
            {accordionItems > 0 && (
              <Flex horizontalAlignment="right">
                <ButtonSecondary
                  size="tiny"
                  onClick={expandCollapseHandler}
                  aria-owns="notification-center-accordion"
                  data-automation-id="NotificationCenterExpandCollapseButton"
                >
                  {indices.length !== defaultIndices.length
                    ? t('ExpandAllButtonText')
                    : t('CollapseAllButtonText')}
                </ButtonSecondary>
              </Flex>
            )}

            <div id="notification-center-accordion">
              <Accordion index={indices} onChange={toggleItem}>
                {isApprovalOfTimeSheetEnabled && (
                  <>
                    {notificationCenterTimesheetItems && (
                      <AccordionItem
                        buttonAutomationId="TimeSheetsToBeClosedHeading"
                        heading={t('TimeSheetsToCloseHeading')}
                        counter={timeSheetsToBeClosedLength}
                        data-automation-id="NotificationCenterItemTimeSheetsToBeClosed"
                      >
                        {notificationCenterTimesheetItems.length > 0 ? (
                          <>
                            {notificationCenterTimesheetItems.map((item, index) =>
                              item.weeklyTimesheetDetails.map((timeSheet, childIndex) => (
                                <ListItem
                                  name={item.employeeFullName}
                                  employeeId={item.employeeUserId}
                                  hours={timeSheet.hours}
                                  normalWorkingTimeHoursInPeriod={timeSheet.normalWorkingTime}
                                  startDate={timeSheet.startDate}
                                  endDate={timeSheet.endDate}
                                  // we can't get and id from backend
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={`timesheet-items-${item.employeeUserId}-${index}-${childIndex}`}
                                />
                              )),
                            )}

                            <Link
                              href={formatUrl(closeTimeSheetReportUrl)}
                              data-automation-id="CloseTimeSheetsReportLink"
                            >
                              {t('TimeSheetsToCloseLinkText')}
                            </Link>
                          </>
                        ) : (
                          <Alert
                            tone="success"
                            data-automation-id="NotificationCenterAlertTimeSheetsToBeClosed"
                          >
                            <Text>{t('TimeSheetsToCloseDoneText')}</Text>
                          </Alert>
                        )}
                      </AccordionItem>
                    )}

                    {timeSheetsToApprove && (
                      <AccordionItem
                        buttonAutomationId="TimeSheetsToApproveHeading"
                        heading={t('TimeSheetsToApproveHeading')}
                        counter={timeSheetsToApprove.length}
                        data-automation-id="NotificationCenterItemTimeSheets"
                      >
                        {timeSheetsToApprove.length > 0 ? (
                          <>
                            {timeSheetsToApprove.map((item, index) => (
                              <ListItem
                                name={item.employeeInfo}
                                employeeId={item.employeeUserId}
                                hours={item.hours}
                                normalWorkingTimeHoursInPeriod={item.normalWorkingTimeHoursInPeriod}
                                url={item.approvalForUserUrl}
                                linkText={t('TimeSheetsToApproveSingleLinkToolTip', {
                                  EMPLOYEE_NAME: item.employeeInfo,
                                })}
                                navigationItemType={item.navigationItemType}
                                // we can't get and id from backend
                                // eslint-disable-next-line react/no-array-index-key
                                key={`timesheet-approve-${item.employeeUserId}-${index}`}
                              />
                            ))}
                            <Link
                              href={formatUrl(approvalUrl)}
                              data-automation-id="ApproveTimeSheetsLink"
                            >
                              {t('TimeSheetsToApproveLinkText')}
                            </Link>
                          </>
                        ) : (
                          <Alert
                            tone="success"
                            data-automation-id="NotificationCenterAlertTimeSheetsToBeApproved"
                          >
                            <Text>{t('TimeSheetsToApproveDoneText')}</Text>
                          </Alert>
                        )}
                      </AccordionItem>
                    )}

                    {absenceToApprove && (
                      <AccordionItem
                        buttonAutomationId="AbsenceToApproveHeading"
                        heading={t('AbsenceToApproveHeading')}
                        counter={absenceToApprove.length}
                        data-automation-id="NotificationCenterItemAbsence"
                      >
                        {absenceToApprove.length > 0 ? (
                          <>
                            {absenceToApprove.map((item, index) => (
                              <ListItem
                                name={item.employeeInfo}
                                employeeId={item.employeeUserId}
                                url={item.approvalForUserUrl}
                                linkText={t('AbsenceToApproveSingleLinkToolTip', {
                                  EMPLOYEE_NAME: item.employeeInfo,
                                })}
                                navigationItemType={item.navigationItemType}
                                startDate={item.startDate}
                                endDate={item.endDate}
                                // we can't get and id from backend
                                // eslint-disable-next-line react/no-array-index-key
                                key={`absence-${item.employeeUserId}-${index}`}
                              />
                            ))}
                            <Link
                              href={formatUrl(approvalUrl)}
                              data-automation-id="ApproveAbsenceLink"
                            >
                              {t('AbsenceToApproveLinkText')}
                            </Link>
                          </>
                        ) : (
                          <Alert
                            tone="success"
                            data-automation-id="NotificationCenterAlertAbsenceToBeApproved"
                          >
                            <Text>{t('AbsenceToApproveDoneText')}</Text>
                          </Alert>
                        )}
                      </AccordionItem>
                    )}
                  </>
                )}

                {isApprovalOfExpensesEnabled && (
                  <>
                    <AccordionItem
                      buttonAutomationId="ExpensesToApproveHeading"
                      heading={t('ExpensesToApproveHeading')}
                      counter={numberOfExpensesPendingApproval}
                      data-automation-id="NotificationCenterItemExpenses"
                    >
                      {numberOfExpensesPendingApproval > 0 ? (
                        approveExpensesUrl && (
                          <Link
                            href={`${formatUrl(approveExpensesUrl)}?expenses=true`}
                            data-automation-id="ApproveExpensesLink"
                          >
                            {t('ExpensesToApproveLinkText')}
                          </Link>
                        )
                      ) : (
                        <Alert
                          tone="success"
                          data-automation-id="NotificationCenterAlertExpensesToBeApproved"
                        >
                          <Text>{t('ExpensesToApproveDoneText')}</Text>
                        </Alert>
                      )}
                    </AccordionItem>

                    <AccordionItem
                      buttonAutomationId="MileageToApproveHeading"
                      heading={t('MileageToApproveHeading')}
                      counter={numberOfMileagePendingApproval}
                      data-automation-id="NotificationCenterItemMileage"
                    >
                      {numberOfMileagePendingApproval > 0 ? (
                        approveExpensesUrl && (
                          <Link
                            href={`${formatUrl(approveExpensesUrl)}?mileage=true`}
                            data-automation-id="ApproveMileageLink"
                          >
                            {t('MileageToApproveLinkText')}
                          </Link>
                        )
                      ) : (
                        <Alert
                          tone="success"
                          data-automation-id="NotificationCenterAlertMileageToBeApproved"
                        >
                          <Text>{t('MileageToApproveDoneText')}</Text>
                        </Alert>
                      )}
                    </AccordionItem>
                  </>
                )}
              </Accordion>
            </div>
          </div>
        </Stack>
      ) : null}
    </ResponseHandler>
  );
};
