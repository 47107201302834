import cx from 'classnames';
import { TStatus } from 'assets/js/variables/status';
import styles from './Count.module.scss';

export interface CountProps {
  className?: string;
  count?: number;
  tone?: TStatus;
}

export const Count = ({ count, tone = 'default', className }: CountProps) => (
  <span
    className={cx(
      styles.count,
      {
        [styles.success]: tone === 'success',
        [styles.warning]: tone === 'warning',
        [styles.error]: tone === 'error',
      },
      className,
    )}
  >
    {count}
  </span>
);
