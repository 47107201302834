import { useTranslation } from 'react-i18next';
import { IFilterItemProperties } from 'src/apis/types/filterListAPI';
import { useFilterDispatch, useActivePanelID } from 'src/stores/FilterStore';
import { useOnClickOutside } from '@timelog/ui-library';
import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { eventKeyEscape, eventKeyTab } from 'src/consts/keyboardKey';
import { getKeyboardFocusableElements } from 'src/utils/accessibility';
import { stringToPascal } from 'src/utils/string';
import InputContainer from '../../../InputContainer';
import styles from './Input.module.scss';

const InputButton = ({
  id,
  type: filterType,
  name: fieldLabel,
  contentUrl,
  childFilters,
  parentFilters,
}: IFilterItemProperties) => {
  const { t } = useTranslation('filter');
  const activePanelID = useActivePanelID();
  const filterId = `FilterID${id}`;
  const ref = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dispatch = useFilterDispatch();

  const onClickOutside = () => {
    if (dispatch && activePanelID === filterId) {
      dispatch({ type: 'DEACTIVATE_PANEL_ID' });
    }
  };

  useOnClickOutside(ref, onClickOutside);

  useEffect(() => {
    const element = dropdownRef.current;
    const [firstElement, ...restElements] = getKeyboardFocusableElements(element);
    const lastElement = restElements[restElements.length - 1];
    const handleTabWithShiftKeyDown = (e: KeyboardEvent) => {
      if (e.key === eventKeyTab && e.shiftKey && dispatch && activePanelID) {
        e.preventDefault();
        dispatch({ type: 'DEACTIVATE_PANEL_ID' });

        if (buttonRef.current) {
          buttonRef.current.focus();
        }
      }
    };

    const handleTabKeyDown = (e: KeyboardEvent) => {
      if (e.key === eventKeyTab && !e.shiftKey && dispatch && activePanelID) {
        dispatch({ type: 'DEACTIVATE_PANEL_ID' });
      }
    };

    if (firstElement) {
      (firstElement as HTMLElement).addEventListener('keydown', handleTabWithShiftKeyDown);
    }

    if (lastElement) {
      (lastElement as HTMLElement).addEventListener('keydown', handleTabKeyDown);
    }

    return () => {
      (firstElement as HTMLElement)?.removeEventListener('keydown', handleTabWithShiftKeyDown);
      (lastElement as HTMLElement)?.removeEventListener('keydown', handleTabKeyDown);
    };
  }, [dispatch, activePanelID, filterId]);

  const toggleFilterPanel = () => {
    if (dispatch) {
      if (activePanelID !== filterId) {
        dispatch({
          type: 'SET_ACTIVATE_PANEL_ID',
          payload: { filterID: filterId },
        });

        dispatch({ type: 'DEACTIVATE_CONTAINER_ID' });
      } else {
        dispatch({
          type: 'DEACTIVATE_PANEL_ID',
        });
      }
    }
  };

  useEffect(() => {
    const element = ref.current;
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === eventKeyEscape && dispatch && activePanelID) {
        e.stopPropagation();
        dispatch({ type: 'DEACTIVATE_PANEL_ID' });

        if (buttonRef.current) {
          buttonRef.current.focus();
        }
      }
    };

    if (element) {
      element.addEventListener('keydown', handleKeyDown);
    }

    return () => element?.removeEventListener('keydown', handleKeyDown);
  }, [dispatch, activePanelID, filterId]);

  return (
    <div className={styles.inputWrapper} data-automation-id={`Input${filterId}`} ref={ref}>
      <button
        type="button"
        ref={buttonRef}
        onClick={toggleFilterPanel}
        data-automation-id={`InputButton${filterId}`}
        aria-controls={filterId}
        aria-expanded={activePanelID === filterId}
        className={classNames(styles.button, { [styles.buttonActive]: activePanelID === filterId })}
      >
        {t(`FilterInputName${stringToPascal(fieldLabel)}` as any)}
      </button>
      {activePanelID === filterId && (
        <div
          id={filterId}
          className={styles.inputField}
          data-automation-id={`FilterPanel${filterId}`}
          hidden={activePanelID !== filterId}
          ref={dropdownRef}
        >
          <InputContainer
            filterType={filterType}
            filterId={filterId}
            fieldLabel={fieldLabel}
            contentUrl={contentUrl}
            childFilters={childFilters}
            parentFilters={parentFilters}
            activateInputContainerRef={buttonRef}
          />
        </div>
      )}
    </div>
  );
};

export default InputButton;
