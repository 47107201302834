import { FC, ReactNode } from 'react';
import { Link } from '@timelog/ui-library';

import { IMenusPropertiesWithPagePath } from 'src/apis/types/menusAPI';

import CollapseItem from './components/CollapseItem';
import LinkInnerContent from './components/LinkInnerContent';
import styles from './VerticalNavigation.module.scss';

interface IconsInterface {
  [key: string]: ReactNode;
}

interface VerticalNavigationProps {
  data: IMenusPropertiesWithPagePath[] | undefined;
  navigationName: string;
  icons?: IconsInterface;
  matchName?: string;
  navigationBarRef: React.RefObject<HTMLElement>;
  onItemSelect?: () => void;
}

export const navId = 'settings-navigation';

export const VerticalNavigation: FC<VerticalNavigationProps> = ({
  data,
  navigationName,
  icons,
  matchName,
  navigationBarRef,
  onItemSelect = () => {},
}) => (
  <nav id={navId} className={styles.Wrapper} aria-label={navigationName} ref={navigationBarRef}>
    {data?.map(({ id, pageUrl = '', pagePath, translation, subMenus }) => {
      const ItemIcon = icons ? icons[id] : null;

      return subMenus && subMenus.length > 0 ? (
        <CollapseItem
          name={id}
          label={translation}
          subMenus={subMenus}
          icon={ItemIcon}
          key={id}
          matchName={matchName}
          onItemSelect={onItemSelect}
        />
      ) : (
        <Link
          block
          useTextColor
          href={pagePath || pageUrl}
          key={id}
          className={styles.ParentLink}
          data-automation-id={`Parent${id}`}
        >
          <LinkInnerContent label={translation} icon={ItemIcon} />
        </Link>
      );
    })}
  </nav>
);
