import { useAttrs, useCommands, useCurrentSelection } from '@remirror/react';
import { ButtonGhost, ButtonPrimary, IconButton, Modal, TextInput } from '@timelog/ui-library';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function UpdateLink() {
  const url = (useAttrs().link()?.href as string) ?? '';
  const [href, setHref] = useState<string>(url);
  const { empty } = useCurrentSelection();
  const [isOpen, setIsOpen] = useState(false);

  const { updateLink, removeLink, selectLink, focus } = useCommands();

  useEffect(() => setHref(url), [url]);

  const onLinkInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHref(e.target.value);
  };

  const close = () => {
    setIsOpen(false);
    focus();
  };

  const removeUrl = () => {
    removeLink();
    close();
  };

  const insertLink = () => {
    selectLink();
    if (href === '') {
      removeUrl();
    } else {
      updateLink({ href });
      close();
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation('richTextEditor');

  return (
    <Modal>
      <Modal.Trigger
        as={IconButton}
        data-automation-id="ibtnFormatInsertLink"
        iconName="formatInsertLink"
        iconSize="compact"
        tooltipText={t('FormatInsertLinkLabel')}
        disabled={empty}
        onClick={() => setIsOpen(true)}
      />
      <Modal.Dialog
        data-automation-id="mdlUpdateLink"
        size="small"
        initialFocusRef={inputRef}
        onDismiss={close}
        isOpen={isOpen}
      >
        <Modal.Header
          heading={t('HeadingSetLinkAddressLabel')}
          closeText={t('CancelActionLabel')}
          closeModal={close}
        />
        <Modal.Content>
          <TextInput
            data-automation-id="txtLinkAddress"
            label={t('LinkAddressLabel')}
            hiddenLabel
            ref={inputRef}
            onChange={onLinkInputChange}
            value={href}
          />
        </Modal.Content>
        <Modal.Footer>
          <ButtonGhost data-automation-id="btngRemoveUrl" onClick={removeUrl}>
            {t('RemoveLinkLabel')}
          </ButtonGhost>
          <ButtonPrimary data-automation-id="btnpInsertLink" onClick={insertLink}>
            {t('ConfirmLinkLabel')}
          </ButtonPrimary>
        </Modal.Footer>
      </Modal.Dialog>
    </Modal>
  );
}
