import { Deck, TextInput, Text } from '@timelog/ui-library';

export default () => (
  <>
    <Deck data-automation-id="PageActionModalMileageRegistration">
      This is Mileage Registration
    </Deck>
    <Text data-automation-id="LabelName">Name</Text>
    <TextInput data-automation-id="TextBoxName" label="Name" value="" name="Name" />
  </>
);
