import { HTMLAttributes, useCallback, useRef, useState } from 'react';
import cx from 'classnames';
import { Link } from '@timelog/ui-library';
import { auxClickPreventNewTabFocus, onClickPreventNewTabFocus } from 'src/utils/clickEvent';
import { ISubMenusWithPagePath } from 'src/apis/types/menusAPI';
import { useSetSubMenuToActive } from 'src/components/Header/hooks';
import TopLink from '../../../TopLink';
import MobileNavTreeToggle from '../../../MobileNavTreeToggle';

import styles from '../../../../Navigation.module.scss';

export interface ISubMenuProps {
  nameId: string;
  subMenus: ISubMenusWithPagePath[];
  isOpen?: boolean;
}

const SubMenu = ({ nameId, subMenus, isOpen }: ISubMenuProps) =>
  subMenus.length > 0 ? (
    <ul
      id={`sub-nav-${nameId}`}
      className={styles.ChildList}
      data-automation-id={`HeaderSubitem${nameId}List`}
      // This is for whatfix to know if sub nav is open
      data-is-open={isOpen}
    >
      {subMenus?.map(({ pageIdentifier, pagePath = '', pageId, translation }) => (
        <li className={styles.ChildItem} key={pageId}>
          <Link
            exact
            useTextColor
            href={pagePath}
            className={styles.ChildLink}
            aria-describedby={`top-level-${nameId}`}
            data-automation-id={`HeaderSubitem${pageIdentifier}Link`}
            onAuxClick={auxClickPreventNewTabFocus}
            onClick={onClickPreventNewTabFocus}
          >
            <span>{translation}</span>
          </Link>
        </li>
      ))}
    </ul>
  ) : null;

interface MenuItemProps extends HTMLAttributes<HTMLLIElement>, ISubMenuProps {
  isParentActive: boolean;
  pagePath?: string;
  translation: string;
  pageIdentifier?: string;
  isSuccess: boolean;
}

export const MenuItem = ({
  nameId,
  isParentActive,
  subMenus,
  pagePath,
  translation,
  pageIdentifier,
  isOpen,
  isSuccess,
}: MenuItemProps) => {
  const [subMenuIsOpen, setSubMenuIsOpen] = useState(isOpen);
  const element = useRef<HTMLLIElement>(null);

  const activeFunction = useCallback(() => {
    setSubMenuIsOpen(true);
  }, []);

  const inActiveFunction = useCallback(() => {
    setSubMenuIsOpen(false);
  }, []);

  useSetSubMenuToActive({
    element,
    activeFunction,
    inActiveFunction,
    isSuccess,
  });

  return (
    <li
      className={cx(styles.ParentItem, { [styles.ParentItemActive]: isParentActive })}
      data-automation-id={`HeaderTopLevel${nameId}Item`}
      ref={element}
    >
      {subMenus.length > 0 || !pagePath ? (
        <div
          id={`top-level-${nameId}`}
          className={`${styles.ParentLink} ${styles.ParentLinkHasSubNav}`}
          data-automation-id={`HeaderTopLevel${nameId}`}
        >
          <TopLink name={translation} hasSubLevel />
        </div>
      ) : (
        <Link
          exact
          href={pagePath}
          className={styles.ParentLink}
          useTextColor
          data-automation-id={`HeaderTopLevel${pageIdentifier}Link`}
        >
          <TopLink name={translation} />
        </Link>
      )}
      {subMenus.length > 0 ? (
        <MobileNavTreeToggle
          id={`sub-nav-${nameId}`}
          name={translation}
          inititalOpen={isParentActive}
          data-automation-id={`HeaderTopLevel${nameId}Button`}
        />
      ) : null}
      <SubMenu subMenus={subMenus} nameId={nameId} isOpen={subMenuIsOpen} />
    </li>
  );
};
