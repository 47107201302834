import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useIsDesignSevenEnabled } from 'src/apis/userPreviewFeaturesAPI';
import { APP_URL } from 'src/consts/cookies';
import { administrativeRoutes } from 'src/routers/MainRouter';

const generateRedirectUrl = (pathname: string, search: string) => {
  const appUrl = Cookies.get(APP_URL);
  const caseInsensitiveAppUrl = pathname?.toLowerCase();
  const sanitizedRoutes = administrativeRoutes?.map((route) => route.replace('/*', ''));
  const isViewingAdministrativePage = sanitizedRoutes?.some((route) =>
    caseInsensitiveAppUrl.startsWith(route.toLowerCase()),
  );

  if (isViewingAdministrativePage) {
    return appUrl ?? '';
  }

  return `${appUrl}${pathname}${search}`;
};

export const generateRedirectUrlForTest = generateRedirectUrl;

export default () => {
  const { pathname, search } = useLocation();
  const { isDesignSevenEnabled, isSuccess } = useIsDesignSevenEnabled();
  useEffect(() => {
    if (!isDesignSevenEnabled && isSuccess) {
      window.location.href = generateRedirectUrl(pathname, search);
    }
  }, [isDesignSevenEnabled, isSuccess, pathname, search]);

  return null;
};
