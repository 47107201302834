import { Stack, Text } from '@timelog/ui-library';
import { HTMLAttributes } from 'react';

interface MultiLineTranslationProps extends HTMLAttributes<HTMLElement> {
  translatedContent: string;
}

export const MultiLineTranslation = ({
  translatedContent,
  ...props
}: MultiLineTranslationProps) => {
  const textToArray = translatedContent.split(/\r?\n/);

  return (
    <Stack {...props}>
      {textToArray.map((text, i) => (
        <Text
          data-automation-id={`MultiLineTranslationParagraph${i}`}
          // eslint-disable-next-line react/no-array-index-key
          key={`${text.replaceAll(' ', '').slice(0, 10)}${i}`}
        >
          {text}
        </Text>
      ))}
    </Stack>
  );
};
