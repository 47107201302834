import { IconButton, Flex, Heading } from '@timelog/ui-library';
import { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useSidePanelDispatch } from 'src/components/SidePanel';
import styles from './Header.module.scss';

interface HeaderProps {
  activateSidePanelButtonRef?: RefObject<HTMLButtonElement>;
}

const Header = ({ activateSidePanelButtonRef }: HeaderProps) => {
  const dispatch = useSidePanelDispatch();
  const { t } = useTranslation('filter');

  return (
    <Flex
      className={styles.title}
      data-automation-id="FilterTitle"
      horizontalAlignment="justify"
      verticalAlignment="center"
    >
      <Heading level={2} as={3} bold data-automation-id="SidePanelHeading">
        {t('FilterPanelHeading')}
      </Heading>
      <IconButton
        data-automation-id="SidePanelCloseBtn"
        iconName="cross"
        tooltipText={t('FilterPanelCloseButton')}
        onClick={() => {
          if (dispatch) dispatch({ type: 'CLOSE_PANEL' });

          if (activateSidePanelButtonRef?.current) {
            activateSidePanelButtonRef.current.focus();
          }
        }}
      />
    </Flex>
  );
};

export default Header;
