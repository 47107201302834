import { IWidget } from '../WidgetType';

export interface IWidgetListProps {
  widgetList: IWidget[];
}

export type TWidgetDashboardActionProps = { type: 'HIDE_WIDGET'; payload: { key: string } };

export default (
  state: IWidgetListProps,
  { type, payload }: TWidgetDashboardActionProps,
): IWidgetListProps => {
  switch (type) {
    case 'HIDE_WIDGET':
      return {
        ...state,
        widgetList: state.widgetList.map(({ key, ...restProps }) =>
          payload.key === key ? { key, ...restProps, isVisible: false } : { key, ...restProps },
        ),
      };
    default:
      return state;
  }
};

export const initWidgetListStore: IWidgetListProps = {
  widgetList: [],
};
