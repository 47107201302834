import {
  FC,
  HTMLAttributes,
  TdHTMLAttributes,
  ThHTMLAttributes,
  ReactNode,
  ButtonHTMLAttributes,
  memo,
} from 'react';
import classNames from 'classnames';

import { THorizontalAlignment } from 'assets/js/variables/alignment';

import { TTableColWidths, TableCellColors } from 'assets/js/variables/table';

import { DateText } from 'components/DateText';
import { NumberText } from 'components/NumberText';
import styles from './Table.module.scss';

interface TableInterface {
  (props: TableProps): JSX.Element;
  Head: FC<HTMLAttributes<HTMLTableSectionElement>>;
  Body: FC<HTMLAttributes<HTMLTableSectionElement>>;
  Foot: FC<HTMLAttributes<HTMLTableSectionElement>>;
  Row: FC<HTMLAttributes<HTMLTableRowElement>>;
  Cell: FC<TableDataCellProps>;
  Header: FC<TableHeaderCellProps>;
  ColGroup: FC<TableColgroupProps>;
  Col: FC<TableColProps>;
}

export interface TableProps extends HTMLAttributes<HTMLTableElement> {
  children: ReactNode;
  /** Header scrolls with content */
  stickyHeader?: boolean;
  /** First column scrolls with content */
  stickyFirstColumn?: boolean;
  /** Last column scrolls with content */
  stickyLastColumn?: boolean;
  stickyFooter?: boolean;
  /** Lower table cells */
  compact?: boolean;
  tableHover?: boolean;
  layout?: 'auto' | 'fixed' | 'initial' | 'inherit';
}

export const Table: TableInterface = ({
  stickyHeader = true,
  stickyFirstColumn,
  stickyLastColumn,
  stickyFooter = false,
  compact,
  tableHover,
  layout = 'auto',
  ...props
}) => (
  <table
    className={classNames(styles.table, {
      [styles.stickyHeader]: stickyHeader,
      [styles.stickyFirstColumn]: stickyFirstColumn,
      [styles.stickyLastColumn]: stickyLastColumn,
      [styles.stickyFooter]: stickyFooter,
      [styles.compact]: compact,
      [styles.tableHover]: tableHover,
      [styles[`tableLayout_${layout}`]]: true,
    })}
    {...props}
  />
);

Table.Head = (props): JSX.Element => <thead {...props} />;

Table.Body = (props): JSX.Element => <tbody {...props} />;

Table.Foot = (props): JSX.Element => <tfoot {...props} />;

Table.Row = (props) => <tr {...props} />;

export type TCellIndentation = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export type TCellText = 'string' | 'date' | 'number' | 'total';
interface TableCellProps {
  /** Cell (TH/TD) content */
  children?: ReactNode;
  alignment?: THorizontalAlignment;
  noBorderLeft?: boolean;
  dividerBorderLeft?: boolean;
  indent?: TCellIndentation;
  type?: TCellText;
  /** Used if cell has eg validation that pops out */
  hasOverflow?: boolean;
}

interface TableDataCellProps extends TableCellProps, TdHTMLAttributes<HTMLTableCellElement> {
  bgColor?: TableCellColors;
  cellFontColor?: string;
}
interface ITableDataCellType {
  type?: TCellText;
  children: ReactNode;
}

const TableDataCellType = ({ type, children }: ITableDataCellType) => {
  switch (type) {
    case 'number':
      return <NumberText value={parseFloat(children as string)} />;
    case 'date':
      return <DateText value={new Date(children as string)} />;
    default:
      // Workaround would be to render a div around it
      // but we don't need it, so let's not waste markup on it
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <>{children}</>;
  }
};

export const TableDataCellTypeForTest = TableDataCellType;

Table.Cell = memo(
  ({
    children,
    alignment = 'left',
    noBorderLeft,
    dividerBorderLeft,
    hasOverflow,
    type = 'string',
    bgColor = 'bgWhite',
    indent = 0,
    cellFontColor,
    ...props
  }) => (
    <td
      className={classNames(styles.tableCell, {
        [styles[`tableCellAlign_${alignment}`]]: alignment !== 'left',
        [styles[`tableCell_${bgColor}`]]: bgColor !== 'bgWhite',
        [styles[`tableCellIndent_${indent}`]]: indent !== 0,
        [styles.tableCellNoBorderLeft]: noBorderLeft,
        [styles.tableCellDividerBorderLeft]: dividerBorderLeft,
        [styles.tableCellTotalColumn]: type === 'total',
        [styles.tableCellFontColorAlertDark]: cellFontColor === 'alertDark',
        [styles.hasOverflow]: hasOverflow,
      })}
      {...props}
    >
      <TableDataCellType type={type}>{children}</TableDataCellType>
    </td>
  ),
);

interface TableHeaderCellProps extends TableCellProps, ThHTMLAttributes<HTMLTableCellElement> {
  isSortable?: boolean;
}

Table.Header = ({
  alignment = 'left',
  noBorderLeft,
  dividerBorderLeft,
  isSortable = false,
  indent = 0,
  ...props
}) => (
  <th
    className={classNames([styles.tableCell, styles.tableCellHeader], {
      [styles[`tableCellAlign_${alignment}`]]: alignment !== 'left',
      [styles.tableCellNoBorderLeft]: noBorderLeft,
      [styles.tableCellDividerBorderLeft]: dividerBorderLeft,
      [styles[`tableCellIndent_${indent}`]]: indent !== 0,
      [styles.isSortable]: isSortable,
    })}
    {...props}
  />
);

interface TableColgroupProps extends HTMLAttributes<HTMLTableColElement> {
  children: ReactNode;
}

Table.ColGroup = (props) => <colgroup {...props} />;

interface TableColProps extends HTMLAttributes<HTMLTableColElement> {
  colWidth?: TTableColWidths;
}

Table.Col = ({ colWidth, className, role, ...props }) => (
  <col
    className={classNames(
      {
        [styles[`colWidth_${colWidth ?? 'auto'}`]]: colWidth,
      },
      className,
    )}
    {...props}
  />
);

interface TableSortButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  sortDirection: 'ASC' | 'DESC' | undefined;
}

export const TableSortButton: FC<TableSortButtonProps> = ({ label, sortDirection, ...props }) => (
  <button className={styles.sortButton} type="button" aria-label={label} {...props}>
    <span
      className={classNames(styles.sortButtonUp, {
        [styles.sortButtonUp_active]: sortDirection === 'DESC',
      })}
      aria-hidden="true"
    />
    <span
      className={classNames(styles.sortButtonDown, {
        [styles.sortButtonDown_active]: sortDirection === 'ASC',
      })}
      aria-hidden="true"
    />
  </button>
);
