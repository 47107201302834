import { IconButton } from '@timelog/ui-library';
import {
  ToastContainer as ToastifyToastContainer,
  cssTransition,
  CloseButtonProps,
} from 'react-toastify';
import { userHasAskedToReduceMotion } from 'src/utils/accessibility';

import styles from './ToastContainer.module.scss';

const disableAnimation = cssTransition({
  enter: styles.DisableAnimation,
  exit: styles.DisableAnimation,
  collapse: false,
});

const bounce = cssTransition({
  enter: styles.ToastItemAnimateIn,
  exit: styles.ToastItemAnimateOut,
});

const ToastCloseButton = ({ closeToast, type }: Partial<CloseButtonProps>) => (
  <IconButton
    border
    round
    size="tiny"
    iconName="cross"
    onClick={closeToast}
    tooltipText="Close"
    className={styles.CloseButton}
    tone={type}
  />
);

export const ToastContainer = () => (
  <ToastifyToastContainer
    position="bottom-right"
    autoClose={false}
    hideProgressBar
    newestOnTop
    closeOnClick={false}
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    closeButton={<ToastCloseButton />}
    icon={false}
    className={styles.ToastContainer}
    toastClassName={styles.ToastItem}
    progressClassName={styles.ProgressBar}
    // limit={1}
    transition={userHasAskedToReduceMotion ? disableAnimation : bounce}
  />
);
