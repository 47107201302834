import { useActive, useCommands } from '@remirror/react';
import { Flex, IconButton, IconButtonGroup } from '@timelog/ui-library';
import { useTranslation } from 'react-i18next';
import { InsertImage, SelectHeading, UpdateLink } from './components';
import { useBuildShortcutString } from './helpers/getOSControlKey';

export const Menu = ({ className }: { className?: string }) => {
  const commands = useCommands();
  const active = useActive();

  const { t } = useTranslation('richTextEditor');

  const shortcutString = useBuildShortcutString();

  return (
    <Flex wrap className={className}>
      <IconButtonGroup>
        <IconButton
          data-automation-id="ibtnFormatUndo"
          iconName="formatUndo"
          tooltipText={shortcutString(t('FormatUndoLabel'), 'Z')}
          disabled={!commands.undo.enabled()}
          onClick={() => {
            commands.undo();
            commands.focus();
          }}
        />
        <IconButton
          data-automation-id="ibtnFormatRedo"
          iconName="formatRedo"
          tooltipText={shortcutString(t('FormatRedoLabel'), 'Y')}
          disabled={!commands.redo.enabled()}
          onClick={() => {
            commands.redo();
            commands.focus();
          }}
        />
      </IconButtonGroup>
      <SelectHeading />
      <IconButtonGroup>
        <IconButton
          data-automation-id="ibtnFormatBold"
          iconName="formatBold"
          tooltipText={shortcutString(t('FormatBoldLabel'), 'B')}
          disabled={!commands.toggleBold.enabled()}
          aria-pressed={active.bold()}
          onClick={() => {
            commands.toggleBold();
            commands.focus();
          }}
        />
        <IconButton
          data-automation-id="ibtnFormatItalic"
          iconName="formatItalic"
          tooltipText={shortcutString(t('FormatItalicLabel'), 'I')}
          disabled={!commands.toggleItalic.enabled()}
          aria-pressed={active.italic()}
          onClick={() => {
            commands.toggleItalic();
            commands.focus();
          }}
        />
        <IconButton
          data-automation-id="ibtnFormatUnderlined"
          iconName="formatUnderlined"
          tooltipText={shortcutString(t('FormatUnderlinedLabel'), 'U')}
          disabled={!commands.toggleUnderline.enabled()}
          aria-pressed={active.underline()}
          onClick={() => {
            commands.toggleUnderline();
            commands.focus();
          }}
        />
        <IconButton
          data-automation-id="ibtnFormatStrike"
          iconName="formatStrikeThrough"
          tooltipText={shortcutString(t('FormatStrikeThroughLabel'))}
          disabled={!commands.toggleStrike.enabled()}
          aria-pressed={active.strike()}
          onClick={() => {
            commands.toggleStrike();
            commands.focus();
          }}
        />
        <IconButton
          data-automation-id="ibtnFormatHorizontalRule"
          iconName="formatHorizontalRule"
          tooltipText={shortcutString(t('FormatHorizontalRuleLabel'))}
          disabled={!commands.insertHorizontalRule.enabled()}
          aria-pressed={active.horizontalRule()}
          onClick={() => {
            commands.insertHorizontalRule();
            commands.focus();
          }}
        />
      </IconButtonGroup>
      <IconButtonGroup>
        <IconButton
          data-automation-id="ibtnFormatListBulleted"
          iconName="formatListBulleted"
          tooltipText={shortcutString(t('FormatListBulletedLabel'))}
          disabled={!commands.toggleBulletList.enabled()}
          aria-pressed={active.bulletList()}
          onClick={() => {
            commands.toggleBulletList();
            commands.focus();
          }}
        />
        <IconButton
          data-automation-id="ibtnFormatListNumbered"
          iconName="formatListNumbered"
          tooltipText={shortcutString(t('FormatListNumberedLabel'))}
          disabled={!commands.toggleOrderedList.enabled()}
          aria-pressed={active.orderedList()}
          onClick={() => {
            commands.toggleOrderedList();
            commands.focus();
          }}
        />
        <IconButton
          data-automation-id="ibtnFormatIndentDecrease"
          iconName="formatIndentDecrease"
          tooltipText={shortcutString(t('FormatIndentDecreaseLabel'))}
          disabled={!commands.decreaseIndent.enabled()}
          onClick={() => {
            commands.decreaseIndent();
            commands.focus();
          }}
        />
        <IconButton
          data-automation-id="ibtnFormatIndentIncrease"
          iconName="formatIndentIncrease"
          tooltipText={shortcutString(t('FormatIndentIncreaseLabel'))}
          disabled={!commands.increaseIndent.enabled()}
          onClick={() => {
            commands.increaseIndent();
            commands.focus();
          }}
        />
      </IconButtonGroup>
      <IconButtonGroup>
        <IconButton
          data-automation-id="ibtnFormatAlignLeft"
          iconName="formatAlignLeft"
          tooltipText={shortcutString(t('FormatAlignLeftLabel'))}
          disabled={
            !(
              active.heading({ nodeTextAlignment: 'center' }) ||
              active.heading({ nodeTextAlignment: 'justify' }) ||
              active.heading({ nodeTextAlignment: 'right' }) ||
              active.paragraph({ nodeTextAlignment: 'center' }) ||
              active.paragraph({ nodeTextAlignment: 'justify' }) ||
              active.paragraph({ nodeTextAlignment: 'right' })
            )
          }
          onClick={() => {
            commands.leftAlign();
            commands.focus();
          }}
        />
        <IconButton
          data-automation-id="ibtnFormatAlignCenter"
          iconName="formatAlignCenter"
          tooltipText={shortcutString(t('FormatAlignCenterLabel'))}
          disabled={
            active.heading({ nodeTextAlignment: 'center' }) ||
            active.paragraph({ nodeTextAlignment: 'center' })
          }
          onClick={() => {
            commands.centerAlign();
            commands.focus();
          }}
        />
        <IconButton
          data-automation-id="ibtnFormatAlignRight"
          iconName="formatAlignRight"
          tooltipText={shortcutString(t('FormatAlignRightLabel'))}
          disabled={
            active.heading({ nodeTextAlignment: 'right' }) ||
            active.paragraph({ nodeTextAlignment: 'right' })
          }
          onClick={() => {
            commands.rightAlign();
            commands.focus();
          }}
        />
        <IconButton
          data-automation-id="ibtnFormatAlignJustify"
          iconName="formatAlignJustify"
          tooltipText={shortcutString(t('FormatAlignJustifyLabel'))}
          disabled={
            active.heading({ nodeTextAlignment: 'justify' }) ||
            active.paragraph({ nodeTextAlignment: 'justify' })
          }
          onClick={() => {
            commands.justifyAlign();
            commands.focus();
          }}
        />
      </IconButtonGroup>
      <IconButtonGroup>
        <InsertImage />
        <UpdateLink />
        <IconButton
          data-automation-id="ibtnFormatCode"
          iconName="formatCode"
          tooltipText={shortcutString(t('FormatCodeLabel'))}
          disabled={active.codeBlock() || !commands.createCodeBlock.enabled({ language: '' })}
          aria-pressed={active.codeBlock()}
          onClick={() => {
            commands.createCodeBlock({ language: '' });
            commands.focus();
          }}
        />
      </IconButtonGroup>
    </Flex>
  );
};
