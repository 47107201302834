import { TDesignSevenPagePathValue, TTLPPagePathValue } from 'src/designSevenPagePath';

type TPageIdenitifer = 'DesignSevenFrontPage';
interface IRouteObject {
  newPath: TDesignSevenPagePathValue;
  oldPaths: Array<TTLPPagePathValue>;
}

type TRedirectObject = Record<TPageIdenitifer, IRouteObject>;

export const RedirectObject: TRedirectObject = {
  DesignSevenFrontPage: {
    newPath: '/home',
    oldPaths: ['/Home/FrontPage', '/'],
  },
};
