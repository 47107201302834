import { GlobalHotKeys } from 'react-hotkeys';
import { useMainSearchDispatch } from 'src/stores/MainSearchStore';

export const shortcutElementNames = {
  mainSearch: 'MainSearch',
};

export const ShortcutSystem = () => {
  const mainSearchDispatch = useMainSearchDispatch();

  const keyMap = {
    TOGGLE_SEARCH: ['F2'],
  };

  const toggleSearch = () => {
    if (mainSearchDispatch) {
      mainSearchDispatch({
        type: 'TOGGLE_SEARCH',
      });

      // main search input
      if (document.getElementsByName(shortcutElementNames.mainSearch).length > 0) {
        document.getElementsByName(shortcutElementNames.mainSearch)[0].focus();
      }
    }
  };

  const handlers = { TOGGLE_SEARCH: toggleSearch };

  return <GlobalHotKeys keyMap={keyMap} handlers={handlers} />;
};
