import { Heading } from '@timelog/ui-library';
import { useGetCurrentPageTitle } from 'src/stores/PageStore';

import styles from './HeaderTitle.module.scss';

export default () => {
  const pageTitle = useGetCurrentPageTitle();

  return (
    <Heading data-automation-id="ScreenHeaderTitle" level={1} as={2} className={styles.HeaderTitle}>
      {pageTitle}
    </Heading>
  );
};
