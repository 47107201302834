import { RefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterContainer, FilterTag, Deck, useOnClickOutside } from '@timelog/ui-library';
import { useSidePanelDispatch } from 'src/components/SidePanel';
import { TFilterType } from 'src/apis/types/filterListAPI';
import { IValue } from 'src/reducers/FilterReducer';
import {
  useSelectedFilterListInArray,
  useFilterDispatch,
  useActiveContainerID,
  useMatchSelectedFilterKey,
} from 'src/stores/FilterStore';
import { stringToPascal } from 'src/utils/string';
import styles from './MainContainer.module.scss';
import InputContainer from '../InputContainer';

interface IFilterItem {
  label: string;
  values: Array<IValue>;
  filterType: TFilterType;
  id: string;
  items: Array<{
    name: string;
  }>;
  contentUrl?: string;
  childFilters?: Array<string>;
  parentFilters?: Array<string>;
}

const FilterItem = ({
  filterType,
  label,
  values,
  id,
  items,
  contentUrl,
  childFilters = [],
  parentFilters = [],
}: IFilterItem) => {
  const ref = useRef<HTMLDivElement>(null);

  const dispatch = useFilterDispatch();
  const activeContainerID = useActiveContainerID();
  const { t } = useTranslation('filter');
  const onClickOutside = () => {
    if (activeContainerID === id && dispatch) {
      dispatch({ type: 'DEACTIVATE_CONTAINER_ID' });
    }
  };
  useOnClickOutside(ref, onClickOutside);
  const matchChild = useMatchSelectedFilterKey(childFilters);

  const removeFilterTag = () => {
    if (dispatch) {
      matchChild?.forEach((filterID) => {
        dispatch({ type: 'RESET_FILTER_ID', payload: { filterID } });
      });

      dispatch({ type: 'REMOVE_FILTER', payload: { filterID: id } });
    }
  };

  const onClick = () => {
    if (dispatch) {
      if (activeContainerID === id) {
        dispatch({ type: 'DEACTIVATE_CONTAINER_ID' });
      } else {
        dispatch({ type: 'SET_ACTIVATE_CONTAINER_ID', payload: { filterID: id } });
        dispatch({ type: 'DEACTIVATE_PANEL_ID' });
      }
    }
  };

  return (
    <div ref={ref}>
      <FilterTag
        title={t(`FilterInputName${stringToPascal(label)}` as any)}
        items={items}
        defaultFilterItemValueLabel={values[0]?.value}
        moreFilterItemLabel={t('ActiveFilterMoreText')}
        toolTipTextCrossButton={t('DeleteFilterItemButton')}
        removeFilterTag={removeFilterTag}
        onClick={onClick}
        panelIsOpen={activeContainerID === id}
      >
        {activeContainerID === id && (
          <div className={styles.inputWrapper}>
            <InputContainer
              filterType={filterType}
              filterId={id}
              fieldLabel={label}
              contentUrl={contentUrl}
              childFilters={childFilters}
              parentFilters={parentFilters}
            />
          </div>
        )}
      </FilterTag>
    </div>
  );
};

interface IMainContainer {
  children: JSX.Element;
  viewOptions?: JSX.Element;
  activateSidePanelButtonRef?: RefObject<HTMLButtonElement>;
}

const MainContainer = ({ children, viewOptions, activateSidePanelButtonRef }: IMainContainer) => {
  const filterList = useSelectedFilterListInArray();
  const sidePaneDispatch = useSidePanelDispatch();
  const dispatch = useFilterDispatch();
  const { t } = useTranslation('filter');

  const toggleFilterPanel = () => {
    if (sidePaneDispatch) {
      sidePaneDispatch({ type: 'TOGGLE_PANEL' });
    }
  };

  return (
    <Deck data-automation-id="MainContainer">
      <Deck.Item>
        <FilterContainer
          activeFilters={filterList.length}
          openFilterPanel={toggleFilterPanel}
          activateSidePanelButtonRef={activateSidePanelButtonRef}
          clearAllFilters={() => dispatch && dispatch({ type: 'RESET_FILTER' })}
          filterButtonText={t('ShowActiveFiltersButton')}
          noActiveFiltersText={t('NoActiveFiltersText')}
          addFilterButtonText={t('AddFilterButtonText')}
          clearAllFiltersButtonText={t('ResetFilterButtonText')}
          localStorageNamePrefix="resource-planner"
        >
          {filterList.map(({ id, ...restProps }) => (
            <FilterItem {...restProps} id={id} key={id} />
          ))}
        </FilterContainer>

        {viewOptions}
      </Deck.Item>

      <Deck.Item hasOverflow>{children}</Deck.Item>
    </Deck>
  );
};

export default MainContainer;
