import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonGhost, Flex, Stack } from '@timelog/ui-library';
import { useGetMilestones } from 'src/apis/milestonesAPI';
import {
  getDateOneWeekFromStartOfWeek,
  getDateTwoWeeksFromStartOfWeek,
  getDateThirtyDaysFromNow,
} from 'src/utils/date';
import WidgetHeader from '../WidgetHeader';
import FeedItems from './components/FeedItems';
import Loading from './components/Loading';
import Error from './components/Error';
import NoResults from './components/NoResults';

interface IMilestoneFeedStatus {
  date: string;
}

const MilestoneFeedStatus = ({ date }: IMilestoneFeedStatus) => {
  const { t } = useTranslation('frontpage');
  const { milestones, isLoading, isError, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useGetMilestones(date);

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <Error />;
  }
  if (milestones && milestones[0] !== undefined) {
    return (
      <Stack verticalMargin="xLarge">
        <FeedItems items={milestones} />

        {isFetchingNextPage && <Loading />}

        {hasNextPage && (
          <Flex horizontalAlignment="right">
            <ButtonGhost
              size="compact"
              data-automation-id="MilestonesLoadMoreButton"
              onClick={() => fetchNextPage()}
              disabled={isFetchingNextPage}
            >
              {t('MilestonesLoadMoreButton')}
            </ButtonGhost>
          </Flex>
        )}
      </Stack>
    );
  }
  return <NoResults />;
};

export default () => {
  const [date, setDate] = useState(getDateOneWeekFromStartOfWeek());
  const { t } = useTranslation('frontpage');

  const handlePeriodSelect = (e: ChangeEvent) => {
    setDate((e.target as HTMLSelectElement).value);
  };

  return (
    <Stack verticalMargin="large">
      <WidgetHeader
        widgetKey="Milestone"
        heading={t('MilestonesHeading')}
        headingAs={2}
        iconName="flag"
        select={{
          label: t('MilestonesSelectLabel'),
          options: [
            {
              value: getDateOneWeekFromStartOfWeek(),
              name: t('MilestonesSelectOptionThisWeek'),
            },
            {
              value: getDateTwoWeeksFromStartOfWeek(),
              name: t('MilestonesSelectOptionThisAndNextWeek'),
            },
            {
              value: getDateThirtyDaysFromNow(),
              name: t('MilestonesSelectOptionNext30Days'),
            },
          ],
          name: 'milestones-period-change',
          onChange: handlePeriodSelect,
        }}
        data-automation-id="MilestonesHeader"
      />
      <MilestoneFeedStatus date={date} />
    </Stack>
  );
};
