import { useSystemAdministrationMenu } from 'src/apis/menusAPI/systemAdministrationAPI';
import VerticalNavigationSearchBar from 'src/components/VerticalNavigationSearchBar';
import LayoutSettings from 'src/components/LayoutSettings';
import { Spinner, Icon } from '@timelog/ui-library';
import LegacyPage from 'src/components/LegacyPage';
import { useTranslation } from 'react-i18next';

const icons = {
  SystemAdministrationGeneralSettingRootPage: <Icon iconName="setting" />,
  SystemAdministrationRegistrationTimeRegistrationSetting: <Icon iconName="timeAndExp" />,
  SystemAdministrationApprovalSetting: <Icon iconName="like" />,
  SystemAdministrationReportActivation: <Icon iconName="report" />,
  SystemAdministrationPMProjectSetting: <Icon iconName="project" />,
  SystemAdministrationEmployeeSetting: <Icon iconName="employee" />,
  SystemAdministrationInvoiceSetting: <Icon iconName="finance" />,
  SystemAdministrationCRMSetting: <Icon iconName="customer" />,
  SystemAdministrationIntegrationSystemIntegration: <Icon iconName="integration" />,
  SystemAdministrationGeneralSettingDepartments: <Icon iconName="generalSetting" />,
  SystemAdministrationGeneralSettingLicenseSetting: <Icon iconName="profile" />,
};

export default () => {
  const { systemAdminMenu, isSystemAdministrationMenuEmpty, error, isLoading } =
    useSystemAdministrationMenu();
  const { t } = useTranslation('systemAdministration');

  return !isSystemAdministrationMenuEmpty ? (
    <LayoutSettings
      navigation={
        <VerticalNavigationSearchBar
          icons={icons}
          t={t}
          menu={systemAdminMenu}
          error={error}
          isLoading={isLoading}
        />
      }
      title={t('HeaderTitle')}
    >
      <LegacyPage automationId="LegacyPage" />
    </LayoutSettings>
  ) : (
    <Spinner data-automation-id="spinner" />
  );
};
