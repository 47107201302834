import { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { IFilterItemProperties, TFilterType } from 'src/apis/types/filterListAPI';
import { FilterInput } from '@timelog/ui-library';
import { useFilterDispatch, useGetFilterValuesById } from 'src/stores/FilterStore';
import SingleDropdown from './components/SingleDropdown';
import MultiSelectDropdown from './components/MultiSelectDropdown';
import SingleDropdownWithSearch from './components/SingleDropdownWithSearch';

interface IChildInputComponent
  extends Pick<IFilterItemProperties, 'contentUrl' | 'childFilters' | 'parentFilters'> {
  filterId: string;
  filterType: TFilterType;
  fieldLabel: string;
  fieldPlaceholder?: string;
  activateInputContainerRef?: RefObject<HTMLButtonElement>;
}

interface IAddFilterItemProps {
  label: string;
  values: Array<string>;
  isInclusive?: boolean;
}

const ChildInputWapper = ({
  filterType,
  filterId,
  fieldLabel,
  contentUrl,
  activateInputContainerRef,
  ...restProps
}: IChildInputComponent) => {
  const defaultValues = useGetFilterValuesById(fieldLabel, filterType);
  const dispatch = useFilterDispatch();
  const { t } = useTranslation('filter');

  const addFilterItem = ({ label, values, isInclusive = true }: IAddFilterItemProps) => {
    if (dispatch) {
      dispatch({
        type: 'ADD_OR_UPDATE_FILTER',
        payload: {
          [fieldLabel]: {
            label,
            values: [{ value: values[0] }],
            filterType,
            isInclude: isInclusive,
            contentUrl,
          },
        },
      });

      dispatch({ type: 'DEACTIVATE_PANEL_ID' });

      if (activateInputContainerRef?.current) {
        activateInputContainerRef.current.focus();
      }
    }
  };

  switch (filterType) {
    case 'SingleSelect':
      return (
        <SingleDropdown
          filterType={filterType}
          filterId={filterId}
          fieldLabel={fieldLabel}
          fieldPlaceholder={t('FilterSelectSinglePlaceholder')}
          contentUrl={contentUrl}
          activateInputContainerRef={activateInputContainerRef}
          {...restProps}
        />
      );
    case 'SingleSelectTypeSearch':
      return (
        <SingleDropdownWithSearch
          filterType={filterType}
          filterId={filterId}
          fieldLabel={fieldLabel}
          fieldPlaceholder={t('FilterSelectSingleWithSearchPlaceholder')}
          contentUrl={contentUrl}
          activateInputContainerRef={activateInputContainerRef}
          {...restProps}
        />
      );
    case 'MultiSelect':
    case 'MultiSelectTypeSearch':
      return (
        <MultiSelectDropdown
          filterType={filterType}
          filterId={filterId}
          fieldLabel={fieldLabel}
          fieldPlaceholder={t('FilterSelectMultiWithSearchPlaceholder')}
          contentUrl={contentUrl}
          activateInputContainerRef={activateInputContainerRef}
          {...restProps}
        />
      );
    case 'Textbox':
    case 'Checkbox':
    case 'RadioButton':
      return (
        <FilterInput
          filterType={filterType}
          filterId={filterId}
          fieldLabel={fieldLabel}
          addFilterItem={addFilterItem}
          {...restProps}
          defaultValue={defaultValues as string}
        />
      );
    default:
      return (
        <FilterInput
          filterType="Textbox"
          filterId={filterId}
          fieldLabel={fieldLabel}
          {...restProps}
          defaultValue=""
          addFilterItem={addFilterItem}
        />
      );
  }
};

export default ChildInputWapper;
