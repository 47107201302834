import { NavLink, NavLinkProps } from 'react-router-dom';

const DefaultLink = ({ children, href, to, className, style, ...restProps }: NavLinkProps) => {
  if (href && href.startsWith('http')) {
    return (
      <a href={href} className={className as string} {...restProps}>
        {children}
      </a>
    );
  }

  return (
    <NavLink to={href || to} className={className} {...restProps}>
      {children}
    </NavLink>
  );
};

export default DefaultLink;
