import { forwardRef, InputHTMLAttributes } from 'react';
import cx from 'classnames';
import logger from 'utils/logger';
import { TStatus } from 'assets/js/variables/status';
import { TInputSizes } from 'assets/js/variables/input';
import { FormFieldValidation, FormField } from 'components/FormField';
import { TextBase } from 'components/Text/TextBase';
import { Icon } from 'components/Icon';
import { useAutoId } from 'hooks/useAutoId';

import styles from './Checkbox.module.scss';

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  /** Label */
  label: string;
  /** Element id attribute if you need control */
  id?: string;
  /** Status (ENUM: Success, Warning, Error) */
  status?: TStatus;
  /** Feedback message */
  validationMsg?: string;
  /** Bold label */
  bold?: boolean;
  /** Hide the label visually */
  hiddenLabel?: boolean;
  /** Input size */
  inputSize?: TInputSizes;
}

type Ref = HTMLInputElement;

export const Checkbox = forwardRef<Ref, CheckboxProps>(
  (
    { id, label, status, validationMsg, bold, hiddenLabel, inputSize = 'normal', ...props },
    ref,
  ) => {
    const autoId = useAutoId(id);
    // We want to have a warning in the console if a label is empty string.
    if (!hiddenLabel && label.trim() === '') logger.warn('`label` must never be an empty string!');

    return (
      <FormField>
        <div
          className={cx(styles.wrapper, {
            [styles[inputSize]]: inputSize,
          })}
        >
          <input
            className={styles.control}
            id={autoId}
            type="checkbox"
            aria-describedby={status ? `checkboxDesc-${autoId}` : undefined}
            ref={ref}
            {...props}
          />
          <label htmlFor={autoId} className={styles.label}>
            <span className={styles.pseudoControl} aria-hidden="true">
              <Icon iconName="check" className={styles.icon} aria-hidden="true" />
            </span>
            <TextBase
              bold={bold}
              as="span"
              className={styles.labelText}
              visuallyHidden={hiddenLabel}
            >
              {label}
            </TextBase>
          </label>
        </div>
        {!!validationMsg && (
          <FormFieldValidation validationMsg={validationMsg} id={`checkboxDesc-${autoId}`} />
        )}
      </FormField>
    );
  },
);

export default Checkbox;
