import { FC, HTMLAttributes, useRef, useCallback, FocusEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useMainSearchDispatch, useIsMainSearchOpen } from 'src/stores/MainSearchStore';

import { IconButton } from '@timelog/ui-library';
import { useSearchKeyboardEvent } from './MainSearchHookHelper';
import Search from './components/Search';
import styles from './MainSearch.module.scss';

interface MainSearchProps extends HTMLAttributes<HTMLButtonElement> {}

export const MainSearch: FC<MainSearchProps> = (props) => {
  const MainSearchRef = useRef<HTMLDivElement>(null);
  const MainSearchTriggerRef = useRef<HTMLButtonElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const searchFilterRef = useRef<HTMLDivElement>(null);
  const mainSearchDispatch = useMainSearchDispatch();
  const mainSearchIsOpen = useIsMainSearchOpen();

  const closeSearch = useCallback(() => {
    if (mainSearchDispatch) {
      mainSearchDispatch({
        type: 'RESET_SEARCH',
      });
    }
  }, [mainSearchDispatch]);

  const toggleSearch = () =>
    mainSearchDispatch &&
    mainSearchDispatch({
      type: 'TOGGLE_SEARCH',
    });

  const handleChange = () => {
    setTimeout(() => {
      searchInputRef?.current?.focus();
    }, 10);

    return toggleSearch();
  };

  const onBlurHandle = ({ target, currentTarget, relatedTarget }: FocusEvent<HTMLDivElement>) => {
    const isSearchIconButton = MainSearchTriggerRef?.current === relatedTarget;
    const isNotSelfTrigger = currentTarget !== target;
    const isNotChildEvent = !currentTarget?.contains(relatedTarget as Node);

    if (isSearchIconButton) {
      return;
    }

    if (isNotSelfTrigger && isNotChildEvent) {
      closeSearch();
    } else {
      const relatedTargetId = (relatedTarget as any)?.getAttribute('data-automation-id');

      if (relatedTargetId === 'HeaderSearchContainer') {
        searchInputRef?.current?.focus();
      } else if (relatedTargetId === 'MainSearchCategoryPanel') {
        searchFilterRef?.current?.focus();
      }
    }
  };

  useSearchKeyboardEvent(
    mainSearchIsOpen,
    closeSearch,
    MainSearchRef.current,
    MainSearchTriggerRef,
  );

  const { t } = useTranslation('header');

  return (
    <div className={styles.Wrapper} ref={MainSearchRef}>
      <IconButton
        ref={MainSearchTriggerRef}
        iconName="search"
        size="large"
        iconSize="large"
        aria-controls="main-search"
        aria-expanded={mainSearchIsOpen}
        onClick={handleChange}
        data-automation-id="HeaderSearchButton"
        tooltipText={t('SearchButton')}
        {...props}
      />
      <div
        id="main-search"
        className={styles.PopOut}
        hidden={!mainSearchIsOpen}
        tabIndex={-1}
        onBlur={onBlurHandle}
        data-automation-id="HeaderSearchContainer"
      >
        <Search inputRef={searchInputRef} searchFilterRef={searchFilterRef} />
      </div>
    </div>
  );
};
