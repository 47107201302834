import { ButtonPrimary, ButtonLinkPrimary } from '@timelog/ui-library';
import { useTranslation } from 'react-i18next';
import { useGetCustomerPortalUrl, useGetProductType } from 'src/apis/indexAPI';
import { useGetShowSignUpButton } from 'src/apis/userSettingsAPI';
import { APP_URL } from 'src/consts/cookies';
import Cookies from 'js-cookie';
import { useGetCurrentPageIdentifier } from 'src/stores/PageStore';
import { useVerifyDesignSevenPage } from 'src/apis/pagesAPI/designSevenAPI';
import styles from './SignUpButton.module.scss';

const onSignUpButtonClick = () => {
  const iframe = document.getElementById('timelog-legacy-page-iframe') as HTMLIFrameElement;

  iframe?.contentWindow?.postMessage(
    { eventId: 'signUpButtonClicked' },
    Cookies.get(APP_URL) || '',
  );
};

export const dataAutomationButtonId = 'HeaderSignUpButton';
export const dataAutomationLinkId = 'HeaderSignUpLink';

const SignUpButton = () => {
  const { t } = useTranslation('header');
  const currentPageIdentifier = useGetCurrentPageIdentifier();
  const { productType } = useGetProductType();
  const {
    isDesignSeven,
    isLoading: isLoadingIsDesignSeven,
    isError: isErrorCustomerPortalUrl,
  } = useVerifyDesignSevenPage(currentPageIdentifier);

  const {
    showSignUpButton,
    isLoading: isLoadingShowSignUpButton,
    isError: isErrorShowSignUpButton,
  } = useGetShowSignUpButton();

  const {
    customerPortalUrl,
    isLoading: isLoadingCustomerPortalUrl,
    isError: isErrorIsDesignSeven,
  } = useGetCustomerPortalUrl();

  const buttonText = productType === 'Time' ? t('TimeProductSignUpButton') : t('SignUpButton');
  const hasCustomerPortal =
    customerPortalUrl !== null && customerPortalUrl !== '' && customerPortalUrl !== undefined;
  const isNewPageAndDoNotUseCustomerPortal = !hasCustomerPortal && isDesignSeven;
  const isLegacyPageAndLetTLPDecideWhatToDo = !isDesignSeven;
  // This means no tracking as we only have that in TLP
  const isNewPageAndUseCustomerPortal = hasCustomerPortal && isDesignSeven;

  if (
    isLoadingShowSignUpButton ||
    isErrorShowSignUpButton ||
    isLoadingCustomerPortalUrl ||
    isErrorCustomerPortalUrl ||
    isLoadingIsDesignSeven ||
    isErrorIsDesignSeven
  ) {
    return null;
  }

  return showSignUpButton && !isNewPageAndDoNotUseCustomerPortal ? (
    <div className={styles.BuyTimeLogButton}>
      {isNewPageAndUseCustomerPortal && (
        <ButtonLinkPrimary
          href={`${customerPortalUrl}/signup`}
          target="_blank"
          rel="noreferrer"
          size="compact"
          data-automation-id={dataAutomationLinkId}
          btnIconLeft="newWindow"
        >
          {buttonText}
        </ButtonLinkPrimary>
      )}
      {isLegacyPageAndLetTLPDecideWhatToDo && (
        <ButtonPrimary
          size="compact"
          data-automation-id={dataAutomationButtonId}
          onClick={onSignUpButtonClick}
        >
          {buttonText}
        </ButtonPrimary>
      )}
    </div>
  ) : null;
};

export default SignUpButton;
