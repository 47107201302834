import { Shimmer, Stack } from '@timelog/ui-library';

export default () => (
  <Stack verticalMargin="xSmall" data-automation-id="MilestonesLoadMessage">
    <Shimmer width="thirdQuarter" />
    <Shimmer width="full" />
    <Shimmer width="half" />
    <Shimmer width="thirdQuarter" />
  </Stack>
);
