import { ChangeEvent, FocusEvent, forwardRef, InputHTMLAttributes, MouseEvent } from 'react';
import { useId } from '@reach/auto-id';
import { VisuallyHidden } from 'components/VisuallyHidden';
import cx from 'classnames';
import { TIconName } from 'assets/js/variables/icon';

import { Icon } from 'components/Icon';
import styles from './DateInput.module.scss';

interface DateInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  value: string | undefined;
  name: string;
  placeholder: string;
  currentInputSelectedName: string;
  onClick: (e: MouseEvent<HTMLInputElement>) => false | void;
  onFocus: (e: FocusEvent<HTMLInputElement>) => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  iconName?: TIconName;
}

export type InputRef = HTMLInputElement;

export const DateInput = forwardRef<InputRef, DateInputProps>(
  (
    {
      iconName,
      label,
      value,
      name,
      placeholder,
      onClick,
      onFocus,
      onChange,
      currentInputSelectedName,
      ...props
    },
    ref,
  ) => {
    const id = useId(props.id);
    return (
      <div
        className={cx(styles.wrapper, {
          [styles.hasFocus]: currentInputSelectedName === name,
        })}
      >
        {iconName && <Icon iconName={iconName} size="small" className={styles.dateInputIcon} />}

        <VisuallyHidden as="label" htmlFor={id}>
          {label}
        </VisuallyHidden>
        <input
          value={value}
          type="text"
          id={id}
          name={name}
          className={styles.dateInput}
          onClick={onClick}
          onFocus={onFocus}
          onChange={onChange}
          placeholder={placeholder}
          autoComplete="off"
          ref={ref}
          {...props}
        />
      </div>
    );
  },
);
