import { forwardRef, ReactNode, InputHTMLAttributes } from 'react';
import classNames from 'classnames';
import {
  FormFieldControl,
  FormFieldLabel,
  FormFieldValidation,
  FormField,
  FormFieldValidationPopOut,
} from 'components/FormField';
import { Icon } from 'components/Icon';
import { TStatus } from 'assets/js/variables/status';
import { TInputFieldWidth, TInputSizes } from 'assets/js/variables/input';
import { useAutoId } from 'hooks/useAutoId';
import { TIconName } from 'assets/js/variables/icon';
import styles from './TextInput.module.scss';

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  /** Element id attribute if you need to have control over it */
  id?: string;
  /** Status (ENUM: Success, Warning, Error) */
  status?: TStatus;
  /** Input width */
  inputWidth?: TInputFieldWidth;
  /** Input size */
  inputSize?: TInputSizes;
  /** Hide label visually */
  hiddenLabel?: boolean;
  /** Feedback message */
  validationMsg?: string;
  /** Align input content right */
  rightAlign?: boolean;
  /** Icon element */
  inputIcon?: TIconName;
  /** Input button to position absolute on input it self eg. clear text */
  children?: ReactNode;
  /** Have validation as a pop out (in table eg.) */
  usePopOutValidation?: boolean;
  /** If input have button right next to the right */
  noBorderRadiusRight?: boolean;
  /** Modifier when content is updated */
  saved?: boolean;
}

export interface TextInputWithLabelProps extends TextInputProps {
  label: string;
  ariaLabelledby?: never;
}

export interface TextInputWithAriaLabelledbyProps extends TextInputProps {
  label?: never;
  ariaLabelledby: string;
}

type Ref = HTMLInputElement;

export const TextInput = forwardRef<
  Ref,
  TextInputWithLabelProps | TextInputWithAriaLabelledbyProps
>(
  (
    {
      id,
      label,
      ariaLabelledby,
      name,
      status = 'default',
      inputWidth,
      inputSize = 'normal',
      hiddenLabel,
      validationMsg,
      rightAlign,
      inputIcon,
      children,
      usePopOutValidation,
      noBorderRadiusRight,
      saved,
      ...props
    },
    ref,
  ) => {
    const autoId = useAutoId(id);
    const ariaDescribedBy = status && validationMsg ? `inputDesc-${autoId}` : undefined;
    return (
      <FormField>
        {(label || label === '') && (
          <FormFieldLabel hiddenLabel={hiddenLabel}>
            <label htmlFor={autoId}>{label}</label>
          </FormFieldLabel>
        )}
        <FormFieldControl inputWidth={inputWidth}>
          {inputIcon && <Icon iconName={inputIcon} aria-hidden="true" className={styles.icon} />}

          <input
            type="text"
            id={autoId}
            name={name}
            className={classNames(styles.wrapper, {
              [styles.noBorderRadiusRight]: noBorderRadiusRight,
              [styles.alignRight]: rightAlign,
              [styles.hasIcon]: children || inputIcon,
              [styles[inputSize]]: inputSize,
              [styles[status]]: status,
              [styles.saved]: saved,
            })}
            aria-describedby={ariaDescribedBy}
            aria-invalid={status === 'error'}
            aria-labelledby={ariaLabelledby}
            ref={ref}
            {...props}
          />
          {children}

          {saved && <Icon iconName="check" size="small" className={styles.savedIcon} />}

          {!!validationMsg && usePopOutValidation && (
            <FormFieldValidationPopOut
              status={status}
              validationMsg={validationMsg}
              id={`inputDesc-${autoId}`}
            />
          )}
        </FormFieldControl>
        {!!validationMsg && !usePopOutValidation && (
          <FormFieldValidation validationMsg={validationMsg} id={`inputDesc-${autoId}`} />
        )}
      </FormField>
    );
  },
);

export default TextInput;
