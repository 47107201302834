import { Text, Heading, ButtonPrimary } from '@timelog/ui-library';
import { useTranslation } from 'react-i18next';
import styles from './WidgetFeedback.module.scss';

export default () => {
  const { t } = useTranslation('frontpage');
  return (
    <div className={styles.widgetContainer}>
      <Heading level={2} as={3} bold data-automation-id="FeedbackWidgetTitle">
        {t('FeedbackWidgetTitle')}
      </Heading>

      <Text data-automation-id="FeedbackWidgetContext">{t('FeedbackWidgetContext')}</Text>
      <div className={styles.button}>
        <ButtonPrimary data-automation-id="FeedbackWidgetButton">
          {t('FeedbackWidgetButton')}
        </ButtonPrimary>
      </div>
    </div>
  );
};
