import { Heading, Stack } from '@timelog/ui-library';
import { IFilterCategoryProperties, IFilterItemProperties } from 'src/apis/types/filterListAPI';
import styles from './InputGroup.module.scss';
import Input from '../Input';

interface IRenderFilterList {
  filterItemList?: Array<IFilterItemProperties>;
}

const RenderInputList = ({ filterItemList = [] }: IRenderFilterList) => (
  <Stack verticalMargin="xxSmall">
    {filterItemList.map(({ id, ...filterItem }) => (
      <Input key={id} id={id} {...filterItem} />
    ))}
  </Stack>
);

const InputGroup = ({ filterCategory, filterItems }: IFilterCategoryProperties) => (
  <Stack verticalMargin="xSmall" key={filterCategory} className={styles.groupList}>
    <div className={styles.groupTitle}>
      <Heading
        key={filterCategory}
        level={3}
        as={4}
        bold
        data-automation-id={`GroupHeading${filterCategory}`}
      >
        {filterCategory}
      </Heading>
    </div>
    <RenderInputList filterItemList={filterItems} />
  </Stack>
);

export default InputGroup;
