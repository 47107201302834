import { Table, TCellText, THorizontalAlignment } from '@timelog/ui-library';
import { memo } from 'react';
import { RTColumn, RTRow } from '../../types/resourcePlanner';
import EditableCell from '../EditableCell';

interface IMemoizedRenderBodyCellPeriodColumn {
  alignment?: THorizontalAlignment;
  editable?: boolean;
  type?: TCellText;
  heatmapCode: string | undefined;
  row: RTRow;
  column: RTColumn;
  isCellEditable: boolean;
  unitType: string;
  reportingType: string;
  handleOnCellValueChanged: ((row: RTRow, column: RTColumn, value: string) => void) | undefined;
  dividerBorderLeft?: boolean;
}

const MemoizedRenderBodyCellPeriodColumn = memo(
  ({
    alignment,
    editable,
    type,
    heatmapCode,
    row,
    column,
    isCellEditable,
    unitType,
    reportingType,
    handleOnCellValueChanged,
    dividerBorderLeft,
  }: IMemoizedRenderBodyCellPeriodColumn) => (
    <Table.Cell
      alignment={alignment}
      bgColor={heatmapCode}
      type={type}
      hasOverflow={editable}
      dividerBorderLeft={dividerBorderLeft}
    >
      <EditableCell
        row={row}
        column={column}
        updateMyData={handleOnCellValueChanged}
        editable={isCellEditable}
        unitType={unitType}
        reportingType={reportingType}
      />
    </Table.Cell>
  ),
  (prevProp, nextProp) => {
    const isSameCellEditable = prevProp.isCellEditable === nextProp.isCellEditable;

    const isSameUnitType = prevProp.unitType === nextProp.unitType;

    const isSameReportingType = prevProp.reportingType === nextProp.reportingType;

    const periodColumnIdSubstring = prevProp.column.id.substring(
      prevProp.column.id.indexOf('_') + 1,
      prevProp.column.id.length,
    );

    const isSameHeatmapColor = prevProp.heatmapCode === nextProp.heatmapCode;

    const prevPropActualValue =
      prevProp.row?.original?.values?.[periodColumnIdSubstring]?.displayValue || '0';

    const nextPropActualValue =
      nextProp.row?.original?.values?.[periodColumnIdSubstring]?.displayValue || '0';

    const isSameActualValue = prevPropActualValue === nextPropActualValue;

    return (
      isSameCellEditable &&
      isSameUnitType &&
      isSameReportingType &&
      isSameHeatmapColor &&
      isSameActualValue
    );
  },
);

export default MemoizedRenderBodyCellPeriodColumn;
