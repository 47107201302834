export const systemAdministrationRoutes = [
  '/Settings/*',
  '/Employee/EmployeeType',
  '/Employee/EmployeeType/Index',
  '/Financial/ProductNumber',
  '/Financial/ProductNumber/Index',
];
export const personalSettingsRoutes = ['/Settings/UserSettings/*'];
export const pageSettingsRoutes = ['/PersonalPageSettings/*'];
export const editProjectTemplateRoutes = ['/Settings/ProjectTemplate/EditProjectTemplate/*'];
