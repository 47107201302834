import { IconButton } from '@timelog/ui-library';
import { useGetCurrentPageId } from 'src/stores/PageStore';
import { useIsBookmarked, useDeleteBookmarkAPI, usePostBookmarkAPI } from 'src/apis/bookmarksAPI';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation('header');
  const {
    location: { href },
  } = window;
  const { isBookmarked, deleteBookmarkUrl } = useIsBookmarked(href);
  const pageId = useGetCurrentPageId();
  const { mutate: deleteBookmark } = useDeleteBookmarkAPI(deleteBookmarkUrl);
  const { mutate: postBookmark } = usePostBookmarkAPI({ pageId, url: href });

  const icon = isBookmarked ? 'bookmarked' : 'bookmark';
  const tooltipText = isBookmarked
    ? t('ScreenHeaderBookmarkUnSave')
    : t('ScreenHeaderBookmarkSave');

  return (
    <IconButton
      iconName={icon}
      iconSize="large"
      tooltipText={tooltipText}
      data-automation-id="ScreenHeaderBookmarkButton"
      onClick={() => (isBookmarked ? deleteBookmark() : postBookmark())}
      tone="light"
    />
  );
};
