import classNames from 'classnames';
import { FC, ReactNode, HTMLAttributes, ElementType } from 'react';
import { TFlexHorizontalAlignment, TFlexVerticalAlignment } from 'assets/js/variables/alignment';
import { TSizes } from 'assets/js/variables/size';

import styles from './Flex.module.scss';

interface FlexInterface {
  (props: FlexProps): JSX.Element;
  Item: FC<FlexItemProps>;
}

export interface FlexProps extends HTMLAttributes<HTMLElement> {
  /** Content */
  children: ReactNode;
  /** Alignment (horizontally) */
  horizontalAlignment?: TFlexHorizontalAlignment;
  /** Alignment (vertically) */
  verticalAlignment?: TFlexVerticalAlignment;
  /** Removes gap between flex children */
  noGap?: boolean;
  /** Decides gap between items in element */
  gap?: TSizes;
  /** Wrap items */
  wrap?: boolean;
  /** Flex Direction */
  direction?: 'row' | 'column' | 'rowReverse' | 'columnReverse';
  /** Render as tag */
  as?: keyof Pick<HTMLElementTagNameMap, 'div' | 'span'>;
}

export const Flex: FlexInterface = ({
  children,
  horizontalAlignment = 'left',
  verticalAlignment = 'center',
  gap = 'xSmall',
  noGap = false,
  wrap = false,
  direction = 'row',
  as: As = 'div',
  className,
  ...props
}) => {
  const Tag = As as ElementType;
  return (
    <Tag
      className={classNames(styles.flex, className, {
        [styles[gap]]: gap,
        [styles.noGap]: noGap,
        [styles.wrap]: wrap,
        [styles[`alignHorizontal_${horizontalAlignment}`]]: horizontalAlignment,
        [styles[`alignVertical_${verticalAlignment}`]]: verticalAlignment,
        [styles[`direction_${direction}`]]: direction,
      })}
      {...props}
    >
      {children}
    </Tag>
  );
};

interface FlexItemProps extends HTMLAttributes<HTMLElement> {
  /** Content */
  children: ReactNode;
  /** Take up rest of the space */
  fillRest?: boolean;
  /** Render as tag */
  as?: string;
}

Flex.Item = ({ fillRest, as: As = 'div', ...props }) => {
  const Tag = As as ElementType;
  return (
    <Tag
      className={classNames(styles.item, {
        [styles.itemFillRest]: fillRest,
      })}
      {...props}
    />
  );
};

export default Flex;
