import { useEffect } from 'react';
import LegacyPage from 'src/components/LegacyPage';
import { usePageDispatch } from 'src/stores/PageStore';

const PAGE_ID = '843c96f4-2782-4b68-b709-34f63f1f74e4';
const PAGE_IDENTIFIER = 'PREVIEW_FEATURE';

const usePageInit = () => {
  const pageDispatch = usePageDispatch();
  useEffect(() => {
    if (pageDispatch) {
      pageDispatch({
        type: 'SET_CURRENT_MODAL_PAGE',
        payload: {
          pageId: PAGE_ID,
          pageIdentifier: PAGE_IDENTIFIER,
          pageTitle: 'Preview Features',
        },
      });
      return () => {
        pageDispatch({
          type: 'SET_CURRENT_MODAL_PAGE',
          payload: { pageId: '', pageIdentifier: '', pageTitle: '' },
        });
      };
    }
    return () => {};
  }, [pageDispatch]);
};

export const PreviewFeatures = () => {
  usePageInit();
  return (
    <LegacyPage
      automationId="LegacyPagePreviewFeatures"
      id="timelog-legacy-page-preview-features-iframe"
      name="timelog-legacy-page-preview-features-iframe"
      isInModal
      src="Settings/UserPreviewFeatures/UserPreviewFeaturesPanel"
    />
  );
};
