import { FC, ImgHTMLAttributes } from 'react';
import classNames from 'classnames';

import { TIconSizes } from 'assets/js/variables/size';
import styles from './Avatar.module.scss';

interface AvatarProps extends ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  size?: TIconSizes;
  noBorder?: boolean;
}

export const Avatar: FC<AvatarProps> = ({ src, size = 'large', noBorder, ...props }) => (
  // We use span here, because we don't want to end up with
  // div inside a fx a button (not valid markup)
  <span
    className={classNames(styles.wrapper, {
      [styles[`${size}`]]: size,
      [styles.noBorder]: noBorder,
    })}
  >
    <img className={styles.image} src={src} alt={props.alt ? props.alt : ''} {...props} />
  </span>
);
