import { toCamel } from 'src/utils/object';

const systemAdminResult = {
  Entities: [
    {
      Properties: {
        PageId: '1eba840c-d879-e311-8dd6-f5e118f57156',
        Name: 'Set up TimeLog here',
        Type: 'SystemAdministration',
        IsParent: true,
        PageUrl: 'http://localhost/tlp/Settings/General/RootPage',
        PageIdentifier: 'SystemAdministrationGeneralSettingRootPage',
        PageType: 'Setting',
        PageVersion: 'DesignSix',
      },
    },
    {
      Properties: {
        PageId: '8af51d4d-3474-e311-a677-8f28e14d6311',
        Name: 'Time and expense registrations',
        Type: 'SystemAdministration',
        IsParent: true,
        PageUrl: 'http://localhost/tlp/Settings/Registrations/TimeTrackingSettings',
        PageIdentifier: 'SystemAdministrationRegistrationTimeRegistrationSetting',
        PageType: 'Setting',
        PageVersion: 'DesignSix',
        SubMenus: [
          {
            PageId: '8af51d4d-3474-e311-a677-8f28e14d6311',
            Name: 'Time tracking setup',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Registrations/TimeTrackingSettings',
            PageIdentifier: 'SystemAdministrationRegistrationTimeRegistrationSetting',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'e52a4e43-23bd-4fac-98d0-f0a70a8373d1',
            Name: 'Expense and travel registration settings',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Registrations/ExpenseSetting',
            PageIdentifier: 'SystemAdministrationRegistrationExpenseSetting',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '6da11b89-3474-e311-a677-8f28e14d6311',
            Name: 'Allowance rates',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Registrations/AllowanceTypes',
            PageIdentifier: 'SystemAdministrationRegistrationAllowanceTypes',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '6ea11b89-3474-e311-a677-8f28e14d6311',
            Name: 'Payment methods',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/PaymentMethod',
            PageIdentifier: 'SystemAdministrationRegistrationPaymentMethod',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '4f85f5c4-a3e7-4e69-ad52-3168d2bdd61e',
            Name: 'Mileage rates',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/MileageRate',
            PageIdentifier: 'SystemAdministrationRegistrationMileageRates',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '70a11b89-3474-e311-a677-8f28e14d6311',
            Name: 'Cars',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Car',
            PageIdentifier: 'SystemAdministrationRegistrationCars',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '71a11b89-3474-e311-a677-8f28e14d6311',
            Name: 'Expense types',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Registrations/ExpenseTypes',
            PageIdentifier: 'SystemAdministrationRegistrationExpenseTypes',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '1441a2bf-1227-4ef3-a795-9c53b4a339ab',
            Name: 'Product list',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Product/Product',
            PageIdentifier: 'SystemAdministrationProductList',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
        ],
      },
    },
    {
      Properties: {
        PageId: '9c97e099-3474-e311-a677-8f28e14d6311',
        Name: 'Approval processes',
        Type: 'SystemAdministration',
        IsParent: true,
        PageUrl: 'http://localhost/tlp/Settings/Approval',
        PageIdentifier: 'SystemAdministrationApprovalSetting',
        PageType: 'Setting',
        PageVersion: 'DesignSix',
        SubMenus: [
          {
            PageId: '9c97e099-3474-e311-a677-8f28e14d6311',
            Name: 'Approval processes',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Approval',
            PageIdentifier: 'SystemAdministrationApprovalSetting',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
        ],
      },
    },
    {
      Properties: {
        PageId: '1db7f563-3574-e311-a677-8f28e14d6311',
        Name: 'Reports',
        Type: 'SystemAdministration',
        IsParent: true,
        PageUrl: 'http://localhost/tlp/Settings/Reports',
        PageIdentifier: 'SystemAdministrationReportActivation',
        PageType: 'Setting',
        PageVersion: 'DesignSix',
        SubMenus: [
          {
            PageId: '1db7f563-3574-e311-a677-8f28e14d6311',
            Name: 'Enable reports',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Reports',
            PageIdentifier: 'SystemAdministrationReportActivation',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '1eb7f563-3574-e311-a677-8f28e14d6311',
            Name: 'Report settings',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Reports/Advanced',
            PageIdentifier: 'SystemAdministrationReportAdvancedSetting',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '1fb7f563-3574-e311-a677-8f28e14d6311',
            Name: 'KPI module settings',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Reports/KPIModuleSettings',
            PageIdentifier: 'SystemAdministrationReportKPISetting',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '1fb7f563-3574-e311-a677-8f28e14d6312',
            Name: 'Project factor settings',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Reports/ProjectFactorSetting',
            PageIdentifier: 'SystemAdministrationReportProjectFactorSetting',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
        ],
      },
    },
    {
      Properties: {
        PageId: '01b7f563-3574-e311-a677-8f28e14d6311',
        Name: 'Projects',
        Type: 'SystemAdministration',
        IsParent: true,
        PageUrl: 'http://localhost/tlp/Settings/Project',
        PageIdentifier: 'SystemAdministrationPMProjectSetting',
        PageType: 'Setting',
        PageVersion: 'DesignSix',
        SubMenus: [
          {
            PageId: '01b7f563-3574-e311-a677-8f28e14d6311',
            Name: 'Project module settings',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Project',
            PageIdentifier: 'SystemAdministrationPMProjectSetting',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '02b7f563-3574-e311-a677-8f28e14d6311',
            Name: 'Default settings for new projects',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Project/StandardValues',
            PageIdentifier: 'SystemAdministrationPMStandardValues',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '03b7f563-3574-e311-a677-8f28e14d6311',
            Name: 'Project invoicing',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Project/Invoicing',
            PageIdentifier: 'SystemAdministrationPMProjectInvoicing',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '04b7f563-3574-e311-a677-8f28e14d6311',
            Name: 'E-mail notifications',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Project/Notifications',
            PageIdentifier: 'SystemAdministrationPMNotifications',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '05b7f563-3574-e311-a677-8f28e14d6311',
            Name: 'Project types',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Project/Types',
            PageIdentifier: 'SystemAdministrationPMProjectTypes',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '06b7f563-3574-e311-a677-8f28e14d6311',
            Name: 'Project categories',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Project/Categories',
            PageIdentifier: 'SystemAdministrationPMProjectCategories',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '671c3a7a-54fd-47ff-8c79-ccab96b7f537',
            Name: 'Contract types',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Project/ContractTypes',
            PageIdentifier: 'SystemAdministrationPMContractTypes',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '07b7f563-3574-e311-a677-8f28e14d6311',
            Name: 'Task types',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Project/TaskTypes',
            PageIdentifier: 'SystemAdministrationPMTaskTypes',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '196c2913-2d9a-4a99-9571-41a3ebc6fdb8',
            Name: 'Milestone types',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/MilestoneType/MilestoneType',
            PageIdentifier: 'SystemAdministrationPMMilestoneTypes',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '08b7f563-3574-e311-a677-8f28e14d6311',
            Name: 'Project stages',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Project/Stages',
            PageIdentifier: 'SystemAdministrationPMProjectStages',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '09b7f563-3574-e311-a677-8f28e14d6311',
            Name: 'Project templates',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/ProjectTemplate',
            PageIdentifier: 'SystemAdministrationPMProjectTemplates',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '0ab7f563-3574-e311-a677-8f28e14d6311',
            Name: 'Settings for estimated completion times (ETCs)',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Project/EtcSetting',
            PageIdentifier: 'SystemAdministrationPMETCSetting',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
        ],
      },
    },
    {
      Properties: {
        PageId: '6ca11b89-3474-e311-a677-8f28e14d6311',
        Name: 'Employees',
        Type: 'SystemAdministration',
        IsParent: true,
        PageUrl: 'http://localhost/tlp/Settings/Employee/EmployeeSetting',
        PageIdentifier: 'SystemAdministrationEmployeeSetting',
        PageType: 'Setting',
        PageVersion: 'DesignSix',
        SubMenus: [
          {
            PageId: '6ca11b89-3474-e311-a677-8f28e14d6311',
            Name: 'Employee module settings',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Employee/EmployeeSetting',
            PageIdentifier: 'SystemAdministrationEmployeeSetting',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '38782a50-c5f3-4edf-8602-fc16956649ea',
            Name: 'Resource management',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Employee/ResourceManagementSetting',
            PageIdentifier: 'SystemAdministrationResourceManagementSetting',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '16b7f563-3574-e311-a677-8f28e14d6311',
            Name: 'User roles and rights management',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Role/RolesOverview',
            PageIdentifier: 'SystemAdministrationEmployeePermission',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '16b7f563-3574-e311-b788-8f28e14d6311',
            Name: 'User rights overview',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Role/RolesMatrixes',
            PageIdentifier: 'SystemAdministrationEmployeeRolesMatrixes',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '17b7f563-3574-e311-a677-8f28e14d6311',
            Name: 'Employee types',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Employee/EmployeeType',
            PageIdentifier: 'SystemAdministrationEmployeeTypes',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '9f3b4eca-089d-453e-83b7-18a8e95d14df',
            Name: 'Normal working time',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Employee/NormHours',
            PageIdentifier: 'SystemAdministrationEmployeeNormHours',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '1fba840c-d879-e311-8dd6-f5e118f57157',
            Name: 'E-mail notifications',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Employee/Notifications',
            PageIdentifier: 'SystemAdministrationEmployeeNotification',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '89ee9950-16d9-4689-bca4-c2bc6883c48b',
            Name: 'Public holiday calendars',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/HolidayCalendar/HolidayCalendar',
            PageIdentifier: 'SystemAdministrationEmployeeHolidayCalendar',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '0737e0aa-b816-4370-b58f-37fbe0be4557',
            Name: 'Salary codes',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Employee/SalaryCodes',
            PageIdentifier: 'SystemAdministrationEmployeeSalaryCodes',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '364e7368-cdbf-4a92-b4a3-efa99a026e45',
            Name: 'Absence codes',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Employee/AbsenceCodes',
            PageIdentifier: 'SystemAdministrationEmployeeAbsenceCodes',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '8a147a09-196a-4853-8fb3-0eeb801bcbbc',
            Name: 'Salary rules',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Employee/SalaryRules',
            PageIdentifier: 'SystemAdministrationEmployeeSalaryRule',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '908dd377-daff-40a9-b12d-78265bc5e686',
            Name: 'Salary groups',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/SalaryGroup/SalaryGroup',
            PageIdentifier: 'SystemAdministrationEmployeeSalaryGroup',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '1bb7f563-3574-e311-a677-8f28e14d6311',
            Name: 'TimeLog Skills settings (CV)',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Employee/Skills',
            PageIdentifier: 'SystemAdministrationEmployeeSkillSetting',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '1cb7f563-3574-e311-a677-8f28e14d6311',
            Name: 'Competences (CV)',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Employee/Competencies',
            PageIdentifier: 'SystemAdministrationEmployeeCompetencies',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '85a37934-e3ae-4934-8de0-b29a9b259d02',
            Name: 'CV status',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Employee/CVStatus',
            PageIdentifier: 'SystemAdministrationEmployeeCVStatus',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
        ],
      },
    },
    {
      Properties: {
        PageId: '0bb7f563-3574-e311-a677-8f28e14d6311',
        Name: 'Finance',
        Type: 'SystemAdministration',
        IsParent: true,
        PageUrl: 'http://localhost/tlp/Settings/Invoice',
        PageIdentifier: 'SystemAdministrationInvoiceSetting',
        PageType: 'Setting',
        PageVersion: 'DesignSix',
        SubMenus: [
          {
            PageId: '0bb7f563-3574-e311-a677-8f28e14d6311',
            Name: 'Invoice settings',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Invoice',
            PageIdentifier: 'SystemAdministrationInvoiceSetting',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '0cb7f563-3574-e311-a677-8f28e14d6311',
            Name: 'Default settings for new invoices',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Invoice/StandardValues',
            PageIdentifier: 'SystemAdministrationInvoiceStandardValues',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '0db7f563-3574-e311-a677-8f28e14d6311',
            Name: 'Invoice designs',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Invoice/Templates',
            PageIdentifier: 'SystemAdministrationInvoiceTemplates',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'f9e54bf6-0d48-413d-b930-4d96da4529c5',
            Name: 'One Click Invoicing (OCI)',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/InvoiceTemplate/OneClickInvoicingTemplate',
            PageIdentifier: 'SystemAdministrationOneClickInvoicingInvoiceTemplate',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '0eb7f563-3574-e311-a677-8f28e14d6311',
            Name: 'Hourly rates',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/HourlyRate',
            PageIdentifier: 'SystemAdministrationInvoiceHourlyRates',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '0fb7f563-3574-e311-a677-8f28e14d6311',
            Name: 'Internal cost prices',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/EmployeeCostRate',
            PageIdentifier: 'SystemAdministrationInvoiceCostPrices',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '10b7f563-3574-e311-a677-8f28e14d6311',
            Name: 'Payment terms',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/PaymentTerm',
            PageIdentifier: 'SystemAdministrationInvoicePaymentTerms',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '11b7f563-3574-e311-a677-8f28e14d6311',
            Name: 'Accounting periods',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/AccountingPeriod',
            PageIdentifier: 'SystemAdministrationInvoiceAccountingPeriod',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'caaa6f7b-c8cf-49c4-9bfb-590673970213',
            Name: 'Unit types',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/UnitType/UnitTypeSettings',
            PageIdentifier: 'SystemAdministrationInvoiceUnitType',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '12b7f563-3574-e311-a677-8f28e14d6311',
            Name: 'Product numbers',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/ProductNumber',
            PageIdentifier: 'SystemAdministrationInvoiceProductNumber',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'c7b531fe-bff1-417b-9c09-f1f8d38a45ff',
            Name: 'Product number mapping',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/ProductNumberDimensionMapping',
            PageIdentifier: 'SystemAdministrationInvoiceProductNumberDimensionMapping',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '13b7f563-3574-e311-a677-8f28e14d6311',
            Name: 'Currency settings',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Invoice/CurrencySetting',
            PageIdentifier: 'SystemAdministrationInvoiceCurrencySetting',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '14b7f563-3574-e311-a677-8f28e14d6311',
            Name: 'Configuration of invoice lines export',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Invoice/InvoiceLineExportSetting',
            PageIdentifier: 'SystemAdministrationInvoiceInvoiceLineExportSetting',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '15b7f563-3574-e311-a677-8f28e14d6311',
            Name: 'Value calculations and fixed-price projects',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/WipSettings',
            PageIdentifier: 'SystemAdministrationInvoiceConfigurationModelOfCalculation',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
        ],
      },
    },
    {
      Properties: {
        PageId: 'f5b6f563-3574-e311-a677-8f28e14d6311',
        Name: 'Customers',
        Type: 'SystemAdministration',
        IsParent: true,
        PageUrl: 'http://localhost/tlp/Settings/Crm',
        PageIdentifier: 'SystemAdministrationCRMSetting',
        PageType: 'Setting',
        PageVersion: 'DesignSix',
        SubMenus: [
          {
            PageId: 'f5b6f563-3574-e311-a677-8f28e14d6311',
            Name: 'Customer module/CRM Settings',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Crm',
            PageIdentifier: 'SystemAdministrationCRMSetting',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'f7b6f563-3574-e311-a677-8f28e14d6311',
            Name: 'E-mail notifications',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Crm/Notifications',
            PageIdentifier: 'SystemAdministrationCRMNotifications',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'f8b6f563-3574-e311-a677-8f28e14d6311',
            Name: 'Extranet',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Crm/Extranet',
            PageIdentifier: 'SystemAdministrationCRMExtranet',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'f9b6f563-3574-e311-a677-8f28e14d6311',
            Name: 'Industry codes',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/IndustryCode',
            PageIdentifier: 'SystemAdministrationCRMIndustryCodes',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'fab6f563-3574-e311-a677-8f28e14d6311',
            Name: 'Groups',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Crm/Groups',
            PageIdentifier: 'SystemAdministrationCRMGroups',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'fbb6f563-3574-e311-a677-8f28e14d6311',
            Name: 'Customer status',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/CustomerStatus',
            PageIdentifier: 'SystemAdministrationCRMStatuses',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'fcb6f563-3574-e311-a677-8f28e14d6311',
            Name: 'Event types',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/EventType',
            PageIdentifier: 'SystemAdministrationCRMEventTypes',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'fdb6f563-3574-e311-a677-8f28e14d6311',
            Name: 'Relations',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/CustomerRelationer',
            PageIdentifier: 'SystemAdministrationCRMRelations',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'feb6f563-3574-e311-a677-8f28e14d6311',
            Name: 'Sales barriers (opportunities)',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Barrier',
            PageIdentifier: 'SystemAdministrationCRMBarriers',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'ffb6f563-3574-e311-a677-8f28e14d6311',
            Name: 'Opportunity status',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/OpportunityStatus',
            PageIdentifier: 'SystemAdministrationCRMOpportunityStatus',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '00b7f563-3574-e311-a677-8f28e14d6311',
            Name: 'Opportunity types',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/OpportunityType',
            PageIdentifier: 'SystemAdministrationCRMOpportunityTypes',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
        ],
      },
    },
    {
      Properties: {
        PageId: 'b36d8244-ee4d-4b00-9a20-733d1482da63',
        Name: 'Integrations and API',
        Type: 'SystemAdministration',
        IsParent: true,
        PageUrl: 'http://localhost/tlp/Settings/SystemIntegration/SystemIntegrations',
        PageIdentifier: 'SystemAdministrationIntegrationSystemIntegration',
        PageType: 'Setting',
        PageVersion: 'DesignSix',
        SubMenus: [
          {
            PageId: 'b36d8244-ee4d-4b00-9a20-733d1482da63',
            Name: 'Integrations',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/SystemIntegration/SystemIntegrations',
            PageIdentifier: 'SystemAdministrationIntegrationSystemIntegration',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '23b7f563-3574-e311-a677-8f28e14d6311',
            Name: 'Transactional API settings',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Integration',
            PageIdentifier: 'SystemAdministrationIntegrationAPISetting',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '04a54842-228e-472b-a55c-e72b4ab17c57',
            Name: 'Reporting API settings',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Integration/ReportingApiSetting',
            PageIdentifier: 'SystemAdministrationIntegrationReportingAPISetting',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '6cb56098-b75f-4f67-a09a-e86ff60e0944',
            Name: 'Reporting API Log',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Integration/ReportingApiLog',
            PageIdentifier: 'SystemAdministrationIntegrationReportingAPILog',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
        ],
      },
    },
    {
      Properties: {
        PageId: '27b7f563-3574-e311-a677-8f28e14d6311',
        Name: 'General settings',
        Type: 'SystemAdministration',
        IsParent: true,
        PageUrl: 'http://localhost/tlp/Settings/Department',
        PageIdentifier: 'SystemAdministrationGeneralSettingDepartments',
        PageType: 'Setting',
        PageVersion: 'DesignSix',
        SubMenus: [
          {
            PageId: 'bedfccf6-4ede-4cc0-a886-d5eb2b13a134',
            Name: 'Legal entity',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/LegalEntity',
            PageIdentifier: 'SystemAdministrationGeneralSettingLegalEntity',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '27b7f563-3574-e311-a677-8f28e14d6311',
            Name: 'Departments',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/Department',
            PageIdentifier: 'SystemAdministrationGeneralSettingDepartments',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '28b7f563-3574-e311-a677-8f28e14d6311',
            Name: 'Number series',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/General/NumberSeries',
            PageIdentifier: 'SystemAdministrationGeneralSettingNumberSeries',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '29b7f563-3574-e311-a677-8f28e14d6311',
            Name: 'Security & access',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/General/SecurityAccess',
            PageIdentifier: 'SystemAdministrationGeneralSettingSecurityAccess',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '2f1ff1fa-9503-4e5f-94ad-312fb13c9151',
            Name: 'TimeLog front page',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/General/FrontPage',
            PageIdentifier: 'SystemAdministrationGeneralSettingFrontPage',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'afa46706-c9cd-4164-aa44-e71a36dfcdc1',
            Name: 'Personal data protection',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/General/PersonalDataProtection',
            PageIdentifier: 'SystemAdministrationGeneralSettingPersonalDataProtection',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '37a74797-3fe7-4168-b60a-8f1b3d9b7531',
            Name: 'System start date',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/General/SystemStartDate',
            PageIdentifier: 'SystemAdministrationGeneralSettingSystemStartDate',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
        ],
      },
    },
    {
      Properties: {
        PageId: '285b6b62-e032-4015-9a62-14a16de0b2e0',
        Name: 'My TimeLog account',
        Type: 'SystemAdministration',
        IsParent: true,
        PageUrl: 'http://localhost/tlp/Settings/General/LicenseSetting',
        PageIdentifier: 'SystemAdministrationGeneralSettingLicenseSetting',
        PageType: 'Setting',
        PageVersion: 'DesignSix',
        SubMenus: [
          {
            PageId: '285b6b62-e032-4015-9a62-14a16de0b2e0',
            Name: 'My TimeLog account',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/General/LicenseSetting',
            PageIdentifier: 'SystemAdministrationGeneralSettingLicenseSetting',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'b50ba9a4-8da3-4df4-88b2-d145ca027655',
            Name: 'Account overview',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl:
              'http://localhost/tlp/Settings/PlatformAccountSettings/PlatformAccountSettings',
            PageIdentifier: 'SystemAdministrationPlatformAccountSettings',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'b6c03bc8-43ef-47b2-9add-74e567c71083',
            Name: 'Purchase licences',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl:
              'http://localhost/tlp/Settings/PlatformLicenceOverview/PlatformLicenceOverview',
            PageIdentifier: 'SystemAdministrationPlatformLicenceOverview',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '9b559fdc-68b3-4070-8c4d-60d02d596e19',
            Name: 'Basic Plus features',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/General/BasicPlusFeatures',
            PageIdentifier: 'SystemAdministrationBasicPlusFeatureSetting',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: 'c6a487e2-062f-40ca-89f3-9bfd433f5c5d',
            Name: 'Change invoice information',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl:
              'http://localhost/tlp/Settings/PlatformInvoiceInformation/PlatformInvoiceInformation',
            PageIdentifier: 'SystemAdministrationPlatformInvoiceInformation',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '717b10ff-a50f-45b0-ada2-2e2fb19634f7',
            Name: 'Purchase history',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl:
              'http://localhost/tlp/Settings/PlatformInvoiceOverview/PlatformInvoiceOverview',
            PageIdentifier: 'SystemAdministrationPlatformInvoiceOverview',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '6fd346c3-548b-4fb6-9d91-5fbd4dfec434',
            Name: 'Action log',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl: 'http://localhost/tlp/Settings/PlatformActionLog/PlatformActionLog',
            PageIdentifier: 'SystemAdministrationPlatformActionLog',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
          {
            PageId: '2205202e-2b1e-417c-99aa-ad704fa41837',
            Name: 'TimeLog responsible',
            Type: 'SystemAdministration',
            IsParent: false,
            PageUrl:
              'http://localhost/tlp/Settings/PlatformAccountSettings/CustomerSystemOwnerSetting',
            PageIdentifier: 'SystemAdministrationPlatformCustomerSystemOwner',
            PageType: 'Setting',
            PageVersion: 'DesignSix',
          },
        ],
      },
    },
  ],
  Links: [{ Href: 'http://localhost/tlp/api/v2/menus/system-administration', Rel: 'self' }],
};

export const systemAdminCamelCase = toCamel(
  systemAdminResult.Entities?.map(({ Properties: { ...restProps } }) => restProps),
);

export default systemAdminResult;
