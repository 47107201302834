import { Flex, Avatar, Icon, Link } from '@timelog/ui-library';
import { ReactNode, useState } from 'react';
import cx from 'classnames';

import HighlightMatchingText from 'src/components/HighlightMatchingText';
import { IResponseSearchItem, TCategory } from 'src/apis/types/searchAPI';

import styles from './SearchItem.module.scss';

interface SubMenu {
  pageUrl: string;
  name: string;
  id: number;
  image: string | null;
}

export interface SearchItemData {
  pageIdentifier: string;
  subMenu?: SubMenu[];
}

interface SearchItemProps {
  searchValue: string;
  searchList: IResponseSearchItem[];
  icon: ReactNode;
  category: TCategory;
  categoryLabel: string;
  onItemSelect: () => void;
}

const SearchItem = ({
  searchValue,
  searchList,
  icon,
  category,
  categoryLabel,
  onItemSelect,
}: SearchItemProps) => {
  const [categoryIsOpen, setCategoryIsOpen] = useState(true);

  return (
    <>
      <button
        data-automation-id={`${category}SearchToggleButton`}
        id={`${category}SearchToggleButton`}
        type="button"
        className={cx(
          {
            [styles.SearchCategory]: category !== 'Customers',
            [styles.SearchCustomersCategory]: category === 'Customers',
          },
          {
            ThemePurple: category === 'Projects',
            ThemeGreen: category === 'Contacts',
            ThemeRed: category === 'Employees',
            ThemeOrange: category === 'Invoices',
          },
        )}
        aria-controls={`${category}SearchContent`}
        aria-expanded={categoryIsOpen}
        onClick={() => setCategoryIsOpen((prev) => !prev)}
      >
        {icon}
        <span>{categoryLabel}</span>
        <span className={styles.SearchCategoryArrow}>
          <Icon iconName="pointdown" />
        </span>
      </button>

      <div
        id={`${category}SearchContent`}
        className={styles.SearchCategoryResults}
        hidden={!categoryIsOpen}
      >
        {searchList?.map(({ name, id, imageUrl, pageUrl }) => (
          <Link
            id={`${category}_${id}`}
            useTextColor
            block
            data-automation-id={`SearchLink${category}${id}`}
            href={pageUrl}
            className={styles.SearchCategoryLink}
            hidden={!categoryIsOpen}
            onClick={onItemSelect}
            key={`${category}_${id}`}
          >
            <Flex>
              {imageUrl && (
                <Avatar
                  data-automation-id={`SearchItemImg${id}`}
                  src={imageUrl}
                  alt=""
                  size="large"
                />
              )}
              <HighlightMatchingText name={name} matchName={searchValue} />
            </Flex>
          </Link>
        ))}
      </div>
    </>
  );
};

export default SearchItem;
