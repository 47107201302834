import { FC } from 'react';
import { Icon, Flex } from '@timelog/ui-library';
import styles from '../../Navigation.module.scss';

interface TopLinkInterface {
  name: string;
  hasSubLevel?: boolean;
}

export const TopLink: FC<TopLinkInterface> = ({ name, hasSubLevel, ...props }) => (
  <Flex gap="xxSmall" verticalAlignment="Center" {...props}>
    <span className={styles.ParentLinkText}>{name}</span>
    {hasSubLevel && (
      <span className={styles.ParentLinkTextIcon} aria-hidden="true">
        <Icon iconName="pointDown" />
      </span>
    )}
  </Flex>
);
