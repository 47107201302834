import { useGetMainMenu } from 'src/apis/menusAPI/mainAPI';
import { Spinner, Text } from '@timelog/ui-library';
import { useLocation } from 'react-router-dom';
import { STATUS } from 'src/consts/reactQuery';
import { removeEmptySpace } from 'src/utils/string';

import styles from '../../Navigation.module.scss';
import { MenuItem } from './components/MenuItem';

const Loading = () => (
  <li className={`${styles.ParentItem} ${styles.ParentItemSpinner}`}>
    <Spinner small />
  </li>
);

const Menu = () => {
  const { menuData, isSuccess } = useGetMainMenu();
  const { pathname } = useLocation();
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {menuData?.map(({ pageIdentifier, pagePath, name, subMenus = [], translation }) => {
        const nameId = removeEmptySpace(name);

        // We need to find out where we are so
        // we can open the correct expand item on load
        const isParentActive = !!subMenus?.find(
          ({ pagePath: subMenuPagePath }) => subMenuPagePath === pathname,
        );

        if (pageIdentifier === 'Home' || pageIdentifier === 'DesignSevenFrontPage') {
          return false;
        }

        return (
          <MenuItem
            nameId={nameId}
            pageIdentifier={pageIdentifier}
            isParentActive={isParentActive}
            pagePath={pagePath}
            translation={translation}
            subMenus={subMenus}
            isSuccess={isSuccess}
            key={`number-menu-${nameId}`}
          />
        );
      })}
    </>
  );
};

export const MenuItems = () => {
  const { status } = useGetMainMenu();

  switch (status) {
    case STATUS.SUCCESS:
      return <Menu />;
    case STATUS.ERROR:
      return <Text>something wrong please refresh</Text>;
    case STATUS.LOADING:
      return <Loading />;
    default:
      return null;
  }
};
