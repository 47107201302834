import { forwardRef, TextareaHTMLAttributes } from 'react';
import classNames from 'classnames';
import logger from 'utils/logger';

import { TStatus } from 'assets/js/variables/status';
import { TInputFieldWidth } from 'assets/js/variables/input';
import { useAutoId } from 'hooks/useAutoId';
import {
  FormFieldControl,
  FormFieldLabel,
  FormFieldValidation,
  FormField,
} from 'components/FormField';

import styles from './Textarea.module.scss';

export interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  /** Label */
  label: string;
  /** Element id attribute if you need control */
  id?: string;
  /** Input width */
  inputWidth?: TInputFieldWidth;
  /** Status (ENUM: Success, Warning, Error) */
  status?: TStatus;
  /** Hide label visually */
  hiddenLabel?: boolean;
  /** Feedback message */
  validationMsg?: string;
}

type Ref = HTMLTextAreaElement;

export const Textarea = forwardRef<Ref, TextareaProps>(
  ({ label, inputWidth, status = 'default', hiddenLabel, validationMsg, ...props }, ref) => {
    const id = useAutoId(props.id);
    // We want to have a warning in the console if a label is empty string.
    if (label === '') logger.warn('`label` must never be an empty string!');

    return (
      <FormField>
        <FormFieldLabel hiddenLabel={hiddenLabel}>
          <label htmlFor={id}>{label}</label>
        </FormFieldLabel>
        <FormFieldControl inputWidth={inputWidth}>
          <textarea
            id={id}
            className={classNames(styles.wrapper, {
              [styles[status]]: status,
            })}
            aria-describedby={status && validationMsg ? `textareaDesc-${id}` : undefined}
            aria-invalid={status === 'error'}
            ref={ref}
            {...props}
          />
        </FormFieldControl>
        {!!validationMsg && (
          <FormFieldValidation validationMsg={validationMsg} id={`textareaDesc-${id}`} />
        )}
      </FormField>
    );
  },
);

export default Textarea;
