import { Updater } from '@tanstack/react-table';
import { Flex, IconButton, Select, TextInput } from '@timelog/ui-library';
import { useState } from 'react';

export const Pagination = ({
  getCanPreviousPage,
  getCanNextPage,
  getPageCount,
  setPageIndex: goToPage,
  nextPage,
  previousPage,
  setPageSize,
  pageIndex,
  pageSize,
}: {
  getCanPreviousPage: boolean;
  getCanNextPage: boolean;
  getPageCount: () => number;
  setPageIndex: (updater: Updater<number>) => void;
  nextPage: () => void;
  previousPage: () => void;
  setPageSize: (updater: Updater<number>) => void;
  pageIndex: number;
  pageSize: number;
}) => {
  const [currentPage, setCurrentPage] = useState(pageIndex + 1);

  return (
    <Flex gap="large">
      <Flex gap="xxSmall">
        <IconButton
          iconName="doubleLeft"
          tooltipText="Go to first page"
          onClick={() => goToPage(0)}
          disabled={!getCanPreviousPage}
          data-automation-id="TablePaginationFirstPage"
        />
        <IconButton
          iconName="pointLeft"
          tooltipText="Go to previous page"
          onClick={() => previousPage()}
          disabled={!getCanPreviousPage}
          data-automation-id="TablePaginationPrevPage"
        />
        <IconButton
          iconName="pointRight"
          tooltipText="Go to next page"
          onClick={() => nextPage()}
          disabled={!getCanNextPage}
          data-automation-id="TablePaginationNextPage"
        />
        <IconButton
          iconName="doubleRight"
          tooltipText="Go to last page"
          onClick={() => goToPage(getPageCount() - 1)}
          disabled={!getCanNextPage}
          data-automation-id="TablePaginationLastPage"
        />
      </Flex>
      <Flex>
        Page{' '}
        <TextInput
          value={currentPage}
          label="Current page"
          hiddenLabel
          type="number"
          min={1}
          max={getPageCount()}
          name="go-to-page"
          inputWidth="xSmall"
          inputSize="small"
          onChange={(e) => {
            const gotToPage = e.target.value ? Number(e.target.value) - 1 : 0;
            goToPage(gotToPage);
            setCurrentPage(parseInt(e.target.value, 10));
          }}
          data-automation-id="TablePaginationInput"
        />
        of {getPageCount()}
      </Flex>
      <Select
        name="how-many-to-show"
        label="How many to show"
        hiddenLabel
        value={pageSize}
        noBorder
        onChange={(e) => {
          setPageSize(Number(e.target.value));
        }}
        options={[
          {
            name: 'Show 10',
            value: 10,
          },
          {
            name: 'Show 20',
            value: 20,
          },
          {
            name: 'Show 50',
            value: 50,
          },
        ]}
        data-automation-id="TablePaginationSelect"
      />
    </Flex>
  );
};
