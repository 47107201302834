import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import IIndex from 'src/apis/types/indexAPI';
import { getLocaleFromLcid } from 'src/utils/locale';

const INDEX_URL_PATH = '/api/v2';
export const INDEX_MSW_STATUS_KEY = 'indexAPI';

export const INDEX_KEY = 'INDEX';

const indexControlFetch = (): Promise<IIndex> =>
  getFetch({ path: INDEX_URL_PATH, key: INDEX_MSW_STATUS_KEY });

const useGetIndexControlQuery = () =>
  useQuery([INDEX_KEY], indexControlFetch, { refetchOnMount: false });

export const useIsValidIndexControlName = (indexControlName: string, action?: string) => {
  const { data, ...restProps } = useGetIndexControlQuery();

  const { links = [] } = data || {};

  const isValidIndexControl = links?.some(({ rel, actions }) => {
    const indexContolIsExist: boolean = rel === indexControlName;
    const actionIsExist = action ? actions?.some(({ name }) => name === action) : true;
    return indexContolIsExist && actionIsExist;
  });

  return {
    isValidIndexControlName: isValidIndexControl,
    ...restProps,
  };
};

export const useGetServerTimeZoneId = () => {
  const { data, ...restProps } = useGetIndexControlQuery();
  return {
    serverTimeZoneId: data?.properties?.serverTimeZoneId,
    ...restProps,
  };
};

export const useGetProductType = () => {
  const { data, ...restProps } = useGetIndexControlQuery();
  return {
    productType: data?.properties?.productType,
    ...restProps,
  };
};

export const useGetHelpInfo = () => {
  const { data, ...restProps } = useGetIndexControlQuery();
  const { version, shortName, edition } = data?.properties || {};
  return {
    helpInfo: {
      version,
      shortName,
      edition,
    },
    ...restProps,
  };
};

export const useGetCustomerPortalUrl = () => {
  const { data, ...restProps } = useGetIndexControlQuery();

  return {
    customerPortalUrl: data?.properties?.customerPortalUrl,
    ...restProps,
  };
};

export const useGetCompanyName = () => {
  const { data, ...restProps } = useGetIndexControlQuery();

  return {
    companyName: data?.properties?.companyName,
    ...restProps,
  };
};

export const useGetSiteLocale = () => {
  const { data, ...restProps } = useGetIndexControlQuery();

  const siteLocale = getLocaleFromLcid(data?.properties?.siteLcid);

  return {
    siteLocale,
    ...restProps,
  };
};

export const useGetSiteStatus = () => {
  const { data, ...restProps } = useGetIndexControlQuery();

  return {
    siteStatus: data?.properties?.siteStatus,
    ...restProps,
  };
};
