import { FC, ReactNode, useRef } from 'react';
import cx from 'classnames';
import { ThemeColors } from '@timelog/ui-library';

import { useSideMenuStore } from 'src/stores/SideMenuStore';
import { HeaderWithNavigation } from 'src/components/Header/Header';
import { ProcessDrawer } from 'src/components/ProcessDrawer';
import AppShell from 'src/components/AppShell';

import styles from './LayoutPage.module.scss';

import { useIframeEvent } from './LayoutPageHookHelper';

interface LayoutPageProps {
  children: ReactNode;
  title?: string;
  theme?: ThemeColors;
}

export const LayoutPage: FC<LayoutPageProps> = ({
  children,
  title,
  theme = 'ThemePink',
  ...props
}) => {
  const mainContentRef = useRef<HTMLDivElement>(null);
  const drawerState = useSideMenuStore();

  useIframeEvent();

  return (
    <AppShell title={title} mainRefElement={mainContentRef}>
      <HeaderWithNavigation />

      {/* The main layout will always hav a processes sidebar */}
      {drawerState?.isSideMenuOpen && <ProcessDrawer />}

      <main
        ref={mainContentRef}
        className={cx(
          styles.Content,
          {
            [styles.DrawerIsPinned]: drawerState?.isSideMenuPinned,
          },
          // The theme should be set on the content as other content is global = pink
          theme,
        )}
        data-automation-id="LayoutPageMainContainer"
        {...props}
      >
        {children}
      </main>
    </AppShell>
  );
};
