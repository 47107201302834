import { Redirect, Switch } from 'react-router-dom';
import { useGetDesignSevenPages } from 'src/apis/pagesAPI/designSevenAPI';
import { RedirectObject } from './RedirectObject';

export default () => {
  const { designSevenPageList, isLoading } = useGetDesignSevenPages();
  if (isLoading) return null;

  return (
    <Switch>
      {Object.entries(RedirectObject).map(([key, { newPath, oldPaths }]) => {
        const isContainPageIdenitifer = designSevenPageList?.find(
          ({ pageIdentifier }) => pageIdentifier === key,
        );
        return isContainPageIdenitifer ? (
          oldPaths.map((oldPath) => (
            <Redirect from={oldPath} to={newPath} key={`routeToNewPath${key}`} exact />
          ))
        ) : (
          <Redirect from={newPath} to={oldPaths[0]} key={`routeToOldPath${key}`} exact />
        );
      })}
    </Switch>
  );
};
