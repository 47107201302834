import designSevenFullResult from '../designSevenFullResult';

export const removeDesignSevenPage = (pageIdentifieries: string[]) => {
  const entities = designSevenFullResult.Entities.filter(
    ({ Properties: { PageIdentifier } }) =>
      !pageIdentifieries.some((pageIdentifier) => pageIdentifier === PageIdentifier),
  );
  return {
    Entities: entities,
    Links: designSevenFullResult.Links,
  };
};
