import { FC, OlHTMLAttributes, HTMLAttributes } from 'react';

import styles from './ListNumbered.module.scss';

export const ListNumbered: FC<OlHTMLAttributes<HTMLOListElement>> = (props) => (
  <ol className={styles.listNumbered} {...props} />
);

export const ListNumberedItem: FC<HTMLAttributes<HTMLLIElement>> = (props) => (
  <li className={styles.listItem} {...props} />
);
