import { ReactNode, ButtonHTMLAttributes, forwardRef } from 'react';
import classnames from 'classnames';

import { TButtonKind, TButtonSizes } from 'assets/js/variables/button';
import { TIconName } from 'assets/js/variables/icon';
import { Icon } from '../Icon';

import styles from './Button.module.scss';

export interface ButtonBaseProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;

  /** Kind of button
   * Use enum for picking one
   */
  kind?: TButtonKind;

  /** Size of button.
   * Use enum for picking one
   */
  size?: TButtonSizes;

  /** Icon element to the left */
  btnIconLeft?: TIconName;

  /** Icon element to the right */
  btnIconRight?: TIconName;
}

export type ButtonRef = HTMLButtonElement;

/**
How do you use the button in general?
* */

export const ButtonBase = forwardRef<ButtonRef, ButtonBaseProps>(
  ({ children, kind = 'primary', btnIconLeft, btnIconRight, size = 'default', ...props }, ref) => (
    <button
      type="button"
      ref={ref}
      {...props}
      className={classnames(styles.wrapper, {
        [styles[kind]]: kind,
        [styles[size]]: size,
        [styles.hasIconLeft]: btnIconLeft,
        [styles.hasIconRight]: btnIconRight,
      })}
    >
      {btnIconLeft && (
        <Icon size="small" iconName={btnIconLeft} className={styles.icon} aria-hidden="true" />
      )}
      {children}
      {btnIconRight && (
        <Icon size="small" iconName={btnIconRight} className={styles.icon} aria-hidden="true" />
      )}
    </button>
  ),
);
