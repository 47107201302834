import { useEffect } from 'react';
import { Route, RouteProps, useLocation } from 'react-router-dom';
import { usePageDispatch } from 'src/stores/PageStore';
import LegacyPage from 'src/components/LegacyPage';
import { useVerifyDesignSevenPage } from 'src/apis/pagesAPI/designSevenAPI';
import LoadingSpinner from 'src/components/LoadingSpinner/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import { translationAnyText } from 'src/utils/translation';

interface PageRouteProps extends Omit<RouteProps, 'render' | 'component'> {
  pageId: string;
  pageIdentifier: string;
  pageTitle: string;
  children: JSX.Element;
}

export default ({ children, pageIdentifier, pageId, pageTitle, ...rest }: PageRouteProps) => {
  // eslint-disable-next-line react/no-unstable-nested-components
  const PageComponent = () => {
    const { isDesignSeven, isLoading } = useVerifyDesignSevenPage(pageIdentifier);
    const pageDispatch = usePageDispatch();
    const { pathname, search } = useLocation();
    const { t } = useTranslation('screenHeaderTitle');

    useEffect(() => {
      if (pageDispatch) {
        pageDispatch({
          type: 'SET_CURRENT_PAGE',
          payload: { pageIdentifier, pageId, pageTitle: translationAnyText(t, pageTitle) },
        });
        pageDispatch({
          type: 'SET_LAST_VISITED_PAGE',
          payload: { pagePath: pathname + search, pageIdentifier },
        });
      }
    });

    if (isLoading) {
      return <LoadingSpinner />;
    }

    return isDesignSeven ? children : <LegacyPage automationId="FallBackLegacyPage" />;
  };

  return <Route {...rest} render={() => <PageComponent />} />;
};
