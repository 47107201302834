import { Text, IconButton } from '@timelog/ui-library';
import { useTranslation } from 'react-i18next';
import { GET_TIME_REGISTRATION_KEY } from 'src/apis/timeRegistrationAccuracyAPI';
import { GET_EXTERNAL_PERCENTAGE_KEY } from 'src/apis/externalPercentageAPI';
import { GET_BILLABLE_PERCENTAGE_KEY } from 'src/apis/billablePercentageAPI';
import { useQueryClient } from '@tanstack/react-query';
import styles from './WidgetError.module.scss';

interface IErrorWidgetProps {
  queryKey:
    | typeof GET_TIME_REGISTRATION_KEY
    | typeof GET_EXTERNAL_PERCENTAGE_KEY
    | typeof GET_BILLABLE_PERCENTAGE_KEY;
}

export const ErrorWidget = ({ queryKey }: IErrorWidgetProps) => {
  const { t } = useTranslation('frontpage');

  const qc = useQueryClient();

  return (
    <div className={styles.ErrorWapper}>
      <Text textAlign="center" data-automation-id="WidgetErrorTitle">
        {t('WidgetErrorTitle')}
      </Text>
      <IconButton
        iconName="reload"
        iconSize="small"
        tooltipText={t('WidgetErrorIcon')}
        data-automation-id="WidgetErrorIcon"
        onClick={() => qc.invalidateQueries([queryKey])}
      />
      <Text textAlign="center" data-automation-id="WidgetErrorDescription">
        {t('WidgetErrorDescription')}
      </Text>
    </div>
  );
};

export default ErrorWidget;
