import { useCommands } from '@remirror/react';
import { ButtonGhost, FileInput, Modal } from '@timelog/ui-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IMAGE_FILE_SIZE_MAX } from 'src/consts/richTextEditor';

export function InsertImage() {
  const commands = useCommands();
  const [isImageTooBig, setIsImageTooBig] = useState(false);
  const [isNotImageFile, setIsNotImageFile] = useState(false);

  const { t } = useTranslation('richTextEditor');

  const handleImageFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (!file.type.startsWith('image/')) {
      setIsNotImageFile(true);
      e.target.value = '';
      return;
    }
    if (file.size > IMAGE_FILE_SIZE_MAX) {
      setIsImageTooBig(true);
      e.target.value = '';
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const image = new Image();
      image.onload = () => {
        const dataUrl = event.target?.result as string;
        commands.insertImage({
          src: dataUrl,
        });
        commands.focus();
      };
      image.src = event.target?.result as string;
    };
    reader.readAsDataURL(file);

    e.target.value = '';
  };

  return (
    <>
      <FileInput
        data-automation-id="btnFIinsertImageFile"
        onChange={handleImageFileChange}
        tooltipText={t('InsertImageLabel')}
      />
      <Modal>
        <Modal.Dialog
          isOpen={isNotImageFile}
          onDismiss={() => setIsImageTooBig(false)}
          size="small"
        >
          <Modal.Header
            heading={t('NotAnImageFileLabel')}
            closeText={t('CancelActionLabel')}
            closeModal={() => setIsImageTooBig(false)}
          />
          <Modal.Footer>
            <ButtonGhost
              data-automation-id="btngCancelNotAnImageFile"
              onClick={() => setIsImageTooBig(false)}
            >
              Cancel
            </ButtonGhost>
          </Modal.Footer>
        </Modal.Dialog>
      </Modal>
      <Modal>
        <Modal.Dialog isOpen={isImageTooBig} onDismiss={() => setIsImageTooBig(false)} size="small">
          <Modal.Header
            heading={t('ImageFileIsTooBigLabel', { SIZE: IMAGE_FILE_SIZE_MAX })}
            closeText={t('CancelActionLabel')}
            closeModal={() => setIsImageTooBig(false)}
          />
          <Modal.Footer>
            <ButtonGhost
              data-automation-id="btngCancelImageTooBig"
              onClick={() => setIsImageTooBig(false)}
            >
              Cancel
            </ButtonGhost>
          </Modal.Footer>
        </Modal.Dialog>
      </Modal>
    </>
  );
}
