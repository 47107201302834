export const designSevenPagePath = {
  home: '/home',
  sample: '/sample-path',
  sampleExample: '/sample-path/example',
  sampleEmployeesId: '/sample-path/employees/:id',
  projects: '/projects',
  resourcePlanner: '/projects/resource-planner',
  resourcePlannerOldUrl: '/employees/resource-planner',
  wrongPath: '/sample-path/wrong-path',
  reports: '/reports/sample-reports',
} as const;

export const TLPPagePath = {
  home: '/Home/FrontPage',
  root: '/',
} as const;

export type TDesignSevenPagePathValue =
  typeof designSevenPagePath[keyof typeof designSevenPagePath];
export type TTLPPagePathValue = typeof TLPPagePath[keyof typeof TLPPagePath];
