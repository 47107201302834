import { Flex, Select, DayRangePicker, TDateRange, useGetLocale } from '@timelog/ui-library';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addMonths, format } from 'date-fns';
import da from 'date-fns/locale/da';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-GB';
import fi from 'date-fns/locale/fi';
import nb from 'date-fns/locale/nb';
import nl from 'date-fns/locale/nl';
import ru from 'date-fns/locale/ru';
import sv from 'date-fns/locale/sv';
import { useGetCurrentLanguage } from 'src/apis/userSettingsAPI';
import { stringToPascal } from 'src/utils/string';
import { translationAnyText } from 'src/utils/translation';
import { getLanguageFromSiteLocale, localeDateFormat } from 'src/utils/date/date';

const localeMap = {
  da,
  de,
  en,
  fi,
  nb,
  nl,
  ru,
  sv,
};

export interface IResouceViewField {
  name: string;
  value?: string;
  options: Array<{
    value: string;
    label: string;
  }>;
}

interface OptionsChangeParameters {
  [key: string]: string;
}

interface ResouceViewOptionsProps {
  onChange?: (item: OptionsChangeParameters[]) => void;
  fields: Array<IResouceViewField>;
  initialDateStart?: Date;
  initialDateEnd?: Date;
}

export default ({
  fields: fieldConfigs,
  onChange,
  initialDateStart = new Date(),
  initialDateEnd = addMonths(new Date(), 3),
}: ResouceViewOptionsProps) => {
  const { t } = useTranslation(['dayPicker', 'resourcePlanner']);
  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.currentTarget;
    onChange?.([{ name, value }]);
  };

  // Date picker options
  const { currentLanguage, isSuccess } = useGetCurrentLanguage();
  const siteLocale = useGetLocale();
  const [dateRange, setDateRange] = useState<TDateRange | undefined>({
    from: initialDateStart,
    to: initialDateEnd,
  });

  const handleDateSelect = () => {
    if (dateRange?.from && dateRange?.to) {
      onChange?.([
        {
          name: 'period-starts-at',
          value: format(dateRange.from, 'yyyy-MM-dd'),
        },
        { name: 'period-ends-at', value: format(dateRange.to, 'yyyy-MM-dd') },
      ]);
    }
  };

  const siteLanguage = getLanguageFromSiteLocale(siteLocale);

  return (
    <Flex horizontalAlignment="justify" verticalAlignment="bottom">
      <DayRangePicker
        mode="range"
        dateRange={dateRange}
        setDateRange={setDateRange}
        fromDate={new Date()}
        weekStartsOn={1}
        showWeekNumber
        locale={isSuccess && currentLanguage ? localeMap[currentLanguage] : localeMap.en}
        onApplyHandler={handleDateSelect}
        inputFromLabel={t('dayPicker:DateInputFromLabel')}
        inputToLabel={t('dayPicker:DateInputToLabel')}
        buttonApplyText={t('dayPicker:ApplyButtonText')}
        buttonCancelText={t('dayPicker:CancelButtonText')}
        dateFormatInvalidMessage={t('dayPicker:NotValidDateFormatMessage')}
        dateInvalidMessage={t('dayPicker:NotValidDate')}
        startDateIsOutOfRangeMessage={t('dayPicker:StartDateIsBeforeAllowedDateMessage')}
        endDateIsOutOfRangeMessage={t('dayPicker:EndDateIsBeforeAllowedDateMessage')}
        dateFormat={localeDateFormat(siteLanguage || 'da')}
        preselectOptions={[
          {
            label: t('dayPicker:PreselectButtonOneWeek'),
            periodType: 'week',
            periodAmount: 1,
          },
          {
            label: t('dayPicker:PreselectButtonOneMonth'),
            periodType: 'month',
            periodAmount: 1,
          },
          {
            label: t('dayPicker:PreselectButtonThreeMonths'),
            periodType: 'month',
            periodAmount: 3,
          },
          {
            label: t('dayPicker:PreselectButtonOneYear'),
            periodType: 'year',
            periodAmount: 1,
          },
        ]}
        labels={{
          labelPrevious: (labelMonth) =>
            `${t('dayPicker:PreviousMonthButtonText')}${
              labelMonth
                ? `: ${labelMonth.toLocaleString(currentLanguage, {
                    month: 'long',
                  })}`
                : ''
            }`,
          labelNext: (labelMonth) =>
            `${t('dayPicker:NextMonthButtonText')}${
              labelMonth
                ? `: ${labelMonth.toLocaleString(currentLanguage, {
                    month: 'long',
                  })}`
                : ''
            }`,
          labelWeekNumber: (weekNumber) =>
            `${t('dayPicker:PickEntireWeekButtonText')} ${weekNumber}`,
        }}
      />

      <Flex>
        {fieldConfigs.map(({ name: fieldName, value: fieldValue, options }) => (
          <Flex.Item key={fieldName}>
            <Select
              name={fieldName}
              label={translationAnyText(
                t,
                `resourcePlanner:FieldOption${stringToPascal(fieldName)}Label`,
              )}
              hiddenLabel
              options={options.map(({ label: name, value }) => ({
                name,
                value,
              }))}
              noBorder
              value={fieldValue}
              onChange={handleSelectChange}
            />
          </Flex.Item>
        ))}
      </Flex>
    </Flex>
  );
};
