type TSideMenuStage =
  | 'NEW_PROJECT'
  | 'EDIT_PROJECT'
  | 'EDIT_PROJECT_STEP'
  | 'IDLE'
  | 'PINNED'
  | 'UNPINNED';

export interface SideMenuStoreProps {
  isSideMenuOpen: boolean;
  isSideMenuPinned: boolean;
  sideMenuStage: TSideMenuStage;
}

export interface SideMenuActionProps {
  type: TSideMenuStage;
  payload?: object;
}

const sideMenuReducer = (
  state: SideMenuStoreProps,
  action: SideMenuActionProps,
): SideMenuStoreProps => {
  switch (action.type) {
    case 'NEW_PROJECT':
      return {
        ...state,
        sideMenuStage: 'NEW_PROJECT',
        isSideMenuOpen: true,
      };
    case 'EDIT_PROJECT':
      return {
        ...state,
        sideMenuStage: 'EDIT_PROJECT',
        isSideMenuOpen: true,
      };
    case 'EDIT_PROJECT_STEP':
      return {
        ...state,
        sideMenuStage: 'EDIT_PROJECT_STEP',
        isSideMenuOpen: true,
      };
    case 'IDLE':
      return {
        ...state,
        sideMenuStage: 'IDLE',
        isSideMenuOpen: false,
        isSideMenuPinned: false,
      };
    case 'PINNED':
      return {
        ...state,
        isSideMenuPinned: true,
      };
    case 'UNPINNED':
      return {
        ...state,
        isSideMenuPinned: false,
      };
    default:
      return state;
  }
};

export const initSideMenuStore: SideMenuStoreProps = {
  isSideMenuPinned: false,
  isSideMenuOpen: false,
  sideMenuStage: 'IDLE',
};
export default sideMenuReducer;
