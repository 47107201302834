import { useGetCustomerPortalUrl } from 'src/apis/indexAPI';
import { ContextMenu } from '@timelog/ui-library';
import { useTranslation } from 'react-i18next';
import { useGetShowSignUpButton } from 'src/apis/userSettingsAPI';

const CustomerPortalButton = () => {
  const { customerPortalUrl } = useGetCustomerPortalUrl();
  const { showSignUpButton } = useGetShowSignUpButton();
  const { t } = useTranslation('header');

  if (!customerPortalUrl || showSignUpButton) {
    return null;
  }

  return (
    <ContextMenu.Link
      data-automation-id="HeaderCustomerPortalButton"
      iconName="newWindow"
      position="Right"
      target="_blank"
      rel="noopener"
      href={customerPortalUrl}
    >
      {t('UserMenuMyTimeLogAccountLink')}
    </ContextMenu.Link>
  );
};

export default CustomerPortalButton;
