import { forwardRef } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  useMobileNavigationStore,
  useMobileNavigationDispatch,
} from 'src/stores/MobileNavigationStore';
import { IconButton } from '@timelog/ui-library';
import styles from '../../Navigation.module.scss';

interface ToggleNavigationProps {
  navId: string;
  className?: string;
}

type ButtonRef = HTMLButtonElement;

export const ToggleNavigation = forwardRef<ButtonRef, ToggleNavigationProps>(
  ({ navId, className }, ref) => {
    const mobileNavigationDispatch = useMobileNavigationDispatch();
    const mobileNavigationState = useMobileNavigationStore();
    const mobileNavIsOpen = mobileNavigationState?.isMobileNavigationOpen;

    const { t } = useTranslation('header');

    return (
      <div className={cx(styles.ToggleNavigation, className)}>
        <IconButton
          iconName={mobileNavIsOpen ? 'cross' : 'menu'}
          size="large"
          iconSize="large"
          onClick={() =>
            mobileNavIsOpen
              ? mobileNavigationDispatch &&
                mobileNavigationDispatch({
                  type: 'CLOSED',
                })
              : mobileNavigationDispatch &&
                mobileNavigationDispatch({
                  type: 'OPEN',
                })
          }
          aria-expanded={mobileNavIsOpen}
          data-automation-id="HeaderToggleNavigationButton"
          aria-controls={navId}
          onKeyDown={(e) =>
            e.key === 'Escape' &&
            mobileNavigationDispatch &&
            mobileNavigationDispatch({
              type: 'CLOSED',
            })
          }
          tooltipText={
            mobileNavIsOpen ? t('CloseMobileNavigationButton') : t('OpenMobileNavigationButton')
          }
          ref={ref}
        />

        {mobileNavIsOpen && (
          <button
            type="button"
            className={styles.CloseLayer}
            aria-label={t('CloseMobileNavigationButton')}
            onClick={() =>
              mobileNavigationDispatch &&
              mobileNavigationDispatch({
                type: 'CLOSED',
              })
            }
            tabIndex={-1}
            data-automation-id="HeaderToggleNavigationOverlay"
          />
        )}
      </div>
    );
  },
);
