import { FC, FormHTMLAttributes, HTMLAttributes, ElementType, ReactNode } from 'react';
import cx from 'classnames';

import { Stack } from 'components/Stack';

import styles from './Form.module.scss';

export interface FormProps extends FormHTMLAttributes<HTMLFormElement> {
  /** Content */
  children: ReactNode;
}

export const Form: FC<FormProps> = ({ children, ...props }) => (
  <form {...props}>
    <Stack verticalMargin="xLarge">{children}</Stack>
  </form>
);

type FormGroupProps<TElement extends keyof JSX.IntrinsicElements> =
  JSX.IntrinsicElements[TElement] & {
    /** Content */
    children: ReactNode;
    /** Make form group more compact */
    compact?: boolean;
    /** Used for radio and checkbox groups */
    booleanGroup?: boolean;
    /** What HTML element to use (default is <fieldset>) */
    as?: TElement | ElementType; // ElementType is needed here so we can set 'tag' as the default value
  };

export function FormGroup<TElement extends keyof JSX.IntrinsicElements>({
  children,
  as: As = 'fieldset',
  compact,
  booleanGroup,
  ...props
}: FormGroupProps<TElement> & HTMLAttributes<HTMLElement>): JSX.Element {
  // We explicitly have to cast `As` here otherwise typescript
  // won't accept it as being a constructor on the next line
  const Tag = As as ElementType;

  return (
    <Tag className={styles.group} {...props}>
      {booleanGroup ? (
        children
      ) : (
        <Stack verticalMargin={compact ? 'xxSmall' : undefined}>{children}</Stack>
      )}
    </Tag>
  );
}

type FormGroupHeadingProps<TElement extends keyof JSX.IntrinsicElements> =
  JSX.IntrinsicElements[TElement] & {
    /** Content */
    children: ReactNode;
    /** Make legend look like normal label */
    asLabel?: boolean;
    /** What HTML element to use (default is <fieldset>) */
    as?: TElement | ElementType; // ElementType is needed here so we can set 'tag' as the default value
  };

export function FormGroupHeading<TElement extends keyof JSX.IntrinsicElements>({
  as: As = 'legend',
  asLabel,
  ...props
}: FormGroupHeadingProps<TElement> & HTMLAttributes<HTMLElement>): JSX.Element {
  // We explicitly have to cast `As` here otherwise typescript
  // won't accept it as being a constructor on the next line
  const Tag = As as ElementType;

  return (
    <Tag
      className={cx(styles.heading, {
        [styles.asLabel]: asLabel,
      })}
      {...props}
    />
  );
}
