import Select, {
  Props,
  IndicatorsContainerProps,
  DropdownIndicatorProps,
  ClearIndicatorProps,
  MultiValueRemoveProps,
  LoadingIndicatorProps,
} from 'react-select';
import logger from 'utils/logger';
import classNames from 'classnames';
import {
  FormFieldControl,
  FormFieldLabel,
  FormFieldValidation,
  FormField,
} from 'components/FormField/FormField';
import { TStatus } from 'assets/js/variables/status';
import { TInputFieldWidth } from 'assets/js/variables/input';
import { useAutoId } from 'hooks/useAutoId';

import { Icon } from 'components/Icon';
import { Spinner } from 'components/Spinner';
import { Flex } from 'components/Flex';
import styles from './FilterSelect.module.scss';

const IndicatorsContainer = ({ children, innerProps }: IndicatorsContainerProps) => (
  <Flex gap="xxSmall" className={styles.iconWrapper} {...innerProps}>
    {children}
  </Flex>
);

const DropdownIndicator = ({ innerProps }: DropdownIndicatorProps) => (
  <span className={styles.icon} {...innerProps}>
    <Icon iconName="pointDown" />
  </span>
);

const ClearIndicator = ({ innerProps }: ClearIndicatorProps) => (
  <span className={styles.icon} {...innerProps}>
    <Icon iconName="cross" />
  </span>
);

const MultiValueRemove = ({ innerProps }: MultiValueRemoveProps) => (
  <span {...innerProps}>
    <Icon iconName="cross" size="small" />
  </span>
);

const LoadingIndicator = ({ innerProps }: LoadingIndicatorProps) => (
  <span {...innerProps}>
    <Spinner small />
  </span>
);

export interface FilterSelectProps extends Props {
  /** Label */
  label: string;
  /** Status (ENUM: Success, Warning, Error) */
  status?: TStatus;
  /** Input width */
  inputWidth?: TInputFieldWidth;
  /** Hide label visually */
  hiddenLabel?: boolean;
  /** Feedback message */
  validationMsg?: string;
}

export const FilterSelect = ({
  id,
  label,
  status = 'default',
  inputWidth = 'auto',
  hiddenLabel = false,
  validationMsg,
  autoFocus,
  ...props
}: FilterSelectProps) => {
  const autoId = useAutoId(id);
  const ariaDescribedBy = status && validationMsg ? ` inputDesc-${autoId}` : undefined;

  // We want to have a warning in the console if a label is empty string.
  if (label === '') logger.warn('`label` must never be an empty string!');

  return (
    <FormField>
      <FormFieldLabel hiddenLabel={hiddenLabel}>
        <label htmlFor={autoId}>{label}</label>
      </FormFieldLabel>
      <FormFieldControl
        inputWidth={inputWidth}
        data-automation-id={`FormFieldReactSelect${label.replaceAll(' ', '')}`}
        className={classNames({ [styles.focusWrapper]: autoFocus })}
      >
        <Select
          inputId={autoId}
          className={styles.wrapper}
          aria-describedby={ariaDescribedBy}
          autoFocus={autoFocus}
          components={{
            IndicatorsContainer,
            DropdownIndicator,
            ClearIndicator,
            MultiValueRemove,
            LoadingIndicator,
          }}
          {...props}
        />
      </FormFieldControl>
      {!!validationMsg && (
        <FormFieldValidation validationMsg={validationMsg} id={`inputDesc-${autoId}`} />
      )}
    </FormField>
  );
};

export default FilterSelect;
