import { SVGAttributes } from 'react';
import { TIconSizes } from 'assets/js/variables/size';
import { Icons, TIconName } from 'assets/js/variables/icon';
import { TStatus } from 'components';
import notificationColors from '../../assets/styles/variables/colors/states/notifications.module.scss';
import grayColors from '../../assets/styles/variables/colors/global/grays.module.scss';
import styles from './Icon.module.scss';

type ExtendedStatus = TStatus | 'light' | 'dark';
export interface SharedIconProps {
  iconName: TIconName;
  size?: TIconSizes;
  tone?: ExtendedStatus;
}

interface IconBaseProps extends SharedIconProps, SVGAttributes<SVGElement> {}

const sizes = {
  small: '16',
  normal: '20',
  large: '24',
  xLarge: '32',
  xxLarge: '60',
};

const tones = {
  default: 'currentColor',
  error: notificationColors.colorAlertDark,
  success: notificationColors.colorSuccessDark,
  warning: notificationColors.colorWarning,
  light: grayColors.colorWhite,
  dark: grayColors.colorGray80,
};

export const Icon = ({ size = 'normal', iconName, tone = 'default', ...props }: IconBaseProps) => (
  <svg
    stroke="currentColor"
    fill={tones[tone] || 'currentColor'}
    strokeWidth="0"
    viewBox="0 0 24 24"
    height={sizes[size] || '1em'}
    width={sizes[size] || '1em'}
    xmlns="http://www.w3.org/2000/svg"
    className={styles.icon}
    {...props}
  >
    {Icons[iconName]}
  </svg>
);
