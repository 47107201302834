import { ChangeEvent, ReactNode } from 'react';
import { Badge, Flex, Heading, Icon, Select, TIconName } from '@timelog/ui-library';

interface WidgetHeaderProps {
  heading: string;
  widgetKey: string;
  headingAs?: 2 | 3;
  iconName?: TIconName;
  count?: number;
  select?: {
    label: string;
    name: string;
    options: Array<{ value: string; name: string }>;
    onChange: (e: ChangeEvent) => void;
  };
  infoModal?: ReactNode;
}

export default ({
  heading,
  headingAs = 3,
  iconName,
  count = 0,
  select,
  widgetKey,
  infoModal,
  ...props
}: WidgetHeaderProps) => (
  <Flex horizontalAlignment="justify" verticalAlignment="center" wrap {...props}>
    <Flex>
      {count > 0 && iconName && (
        <Badge count={count} tone="warning">
          <Icon
            iconName={iconName}
            size="large"
            data-automation-id={`WidgetHeaderIcon${widgetKey}`}
          />
        </Badge>
      )}
      {!count && iconName && (
        <Icon
          iconName={iconName}
          size="large"
          data-automation-id={`WidgetHeaderIcon${widgetKey}`}
        />
      )}
      <Heading level={2} as={headingAs} bold data-automation-id={`WidgetHeaderHeading${widgetKey}`}>
        {heading}
      </Heading>
    </Flex>

    {(select || infoModal) && (
      <Flex.Item>
        {select && (
          <Select
            label={select.label}
            options={select.options}
            name={select.name}
            hiddenLabel
            noBorder
            onChange={select.onChange}
            data-automation-id={`WidgetHeaderSelect${widgetKey}`}
          />
        )}

        {infoModal && infoModal}
      </Flex.Item>
    )}
  </Flex>
);
